//! FETCH PADLOCKS TYPES
export const FETCH_PADLOCKS_PENDING = 'FETCH_PADLOCKS_PENDING';
export const FETCH_PADLOCKS_FAIL = 'FETCH_PADLOCKS_FAIL';
export const FETCH_PADLOCKS_SUCCESS = 'FETCH_PADLOCKS_SUCCESS';

//! CREATE PADLOCKS TYPES
export const CREATE_PADLOCK_PENDING = 'CREATE_PADLOCK_PENDING';
export const CREATE_PADLOCK_FAIL = 'CREATE_PADLOCK_FAIL';
export const CREATE_PADLOCK_SUCCESS = 'CREATE_PADLOCK_SUCCESS';

//! FETCH PADLOCK TYPES
export const FETCH_PADLOCK_PENDING = 'FETCH_PADLOCK_PENDING';
export const FETCH_PADLOCK_FAIL = 'FETCH_PADLOCK_FAIL';
export const FETCH_PADLOCK_SUCCESS = 'FETCH_PADLOCK_SUCCESS';

//! UPDATE PADLOCK TYPES
export const UPDATE_PADLOCK_PENDING = 'UPDATE_PADLOCK_PENDING';
export const UPDATE_PADLOCK_FAIL = 'UPDATE_PADLOCK_FAIL';
export const UPDATE_PADLOCK_SUCCESS = 'UPDATE_PADLOCK_SUCCESS';

//! DELETE PADLOCK TYPES
export const DELETE_PADLOCK_PENDING = 'DELETE_PADLOCK_PENDING';
export const DELETE_PADLOCK_FAIL = 'DELETE_PADLOCK_FAIL';
export const DELETE_PADLOCK_SUCCESS = 'DELETE_PADLOCK_SUCCESS';

//! MOVE PADLOCKS TYPES
export const MOVE_PADLOCKS_PENDING = 'MOVE_PADLOCKS_PENDING';
export const MOVE_PADLOCKS_FAIL = 'MOVE_PADLOCKS_FAIL';
export const MOVE_PADLOCKS_SUCCESS = 'MOVE_PADLOCKS_SUCCESS';