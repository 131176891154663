import * as types from '../actions/types/code';
import * as authTypes from '../actions/types/auth';

const initialState = {
    creating: false,
    verifying: false,
    codeLen: null,
    hasVerified: false
};

const codeReducers = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.CREATE_CODE_FAIL:
        case authTypes.CHANGE_PASSWORD_SUCCESS:
        case authTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                creating: false,
                verifying: false,
                codeLen: null,
                hasVerified: false
            };
        case types.VERIFY_CODE_FAIL:
            return {
                ...state,
                verifying: false,
                hasVerified: false
            };
        case types.CREATE_CODE_PENDING:
            return {
                ...state,
                creating: true,
                hasVerified: false
            };
        case types.VERIFY_CODE_PENDING:
            return {
                ...state,
                verifying: true
            };
        case types.VERIFY_CODE_SUCCESS:
            return {
                ...state,
                verifying: false,
                codeLen: null,
                hasVerified: true
            };
        case types.CREATE_CODE_SUCCESS:
            return {
                ...state,
                creating: false,
                codeLen: payload,
                hasVerified: false
            };
        default:
            return state;
    }
};
export default codeReducers;