import React from 'react';
import { useSelector } from 'react-redux';
import {
    closeDialog
} from '../../../store/actions/dialog';
import { isEmpty } from 'jvh-is-empty';


// utils
import Constants from '../../../utils/Constants';

const Dialog = (props) => {
    const { isOpen, dialogData } = useSelector((state) => state.dialog);
    const { dialogTitle, xmlFunc, width} = dialogData;

    const styles = {
        dialogWindow: {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            zIndex: '999',
            WebkitTransition: 'all 0.3s',
            MozTransition: 'all 0.3s',
            OTransition: 'all 0.3s',
            transition: 'all 0.3s',
            visibility: isOpen ? 'visible' : 'hidden',
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? 'auto' : 'none'
        },
        dialogContainer: {
            width: '90%',
            maxWidth: `${width}rem`,
            position: 'absolute',
            top: '50%',
            left: '50%',
            padding: '1rem',
            WebkitTransform: 'translate(-50%, -50%)',
            MozTransform: 'translate(-50%, -50%)',
            OTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
            background: Constants.main,
            borderRadius: '8px',
            // overflow: 'hidden',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        dialogTitle: {
            textAlign: 'center',
            margin: '1rem',
            fontWeight: 700,
            fontSize: '1.6rem'
        }
    };
    return (
        <div
            style={styles.dialogWindow}
            id="open-dialog"
            className="dialog-window"
        >
            <div style={styles.dialogContainer}>
                <h3 style={styles.dialogTitle} className="light-text" id="DialogContent">
                    {!isEmpty(dialogTitle) && dialogTitle}
                </h3>
                {isOpen && xmlFunc(closeDialog)}
            </div>
        </div>
    );
};

export default Dialog;