//! FETCH LOCKERS TYPES
export const FETCH_LOCKERS_PENDING = 'FETCH_LOCKERS_PENDING';
export const FETCH_LOCKERS_FAIL = 'FETCH_LOCKERS_FAIL';
export const FETCH_LOCKERS_SUCCESS = 'FETCH_LOCKERS_SUCCESS';

//! CREATE LOCKERS TYPES
export const CREATE_LOCKER_PENDING = 'CREATE_LOCKER_PENDING';
export const CREATE_LOCKER_FAIL = 'CREATE_LOCKER_FAIL';
export const CREATE_LOCKER_SUCCESS = 'CREATE_LOCKER_SUCCESS';

//! UPDATE LOCKER TYPES
export const UPDATE_LOCKER_PENDING = 'UPDATE_LOCKER_PENDING';
export const UPDATE_LOCKER_FAIL = 'UPDATE_LOCKER_FAIL';
export const UPDATE_LOCKER_SUCCESS = 'UPDATE_LOCKER_SUCCESS';

//! DELETE LOCKER TYPES
export const DELETE_LOCKER_PENDING = 'DELETE_LOCKER_PENDING';
export const DELETE_LOCKER_FAIL = 'DELETE_LOCKER_FAIL';
export const DELETE_LOCKER_SUCCESS = 'DELETE_LOCKER_SUCCESS';

//! FETCH LOCKER TYPES
export const FETCH_LOCKER_PENDING = 'FETCH_LOCKER_PENDING';
export const FETCH_LOCKER_FAIL = 'FETCH_LOCKER_FAIL';
export const FETCH_LOCKER_SUCCESS = 'FETCH_LOCKER_SUCCESS';
