import React from 'react';
import Wrapper from '../../components/Layout/Wrapper/Wrapper';

const Terms = () => {

    return (
        <Wrapper>
            <article className="large-shadow" style={styles.root}>
                <img style={styles.imgTerm} alt="term1" src={require('../../assets/term1.png')} />
                <img style={styles.imgTerm} alt="term2" src={require('../../assets/term2.png')} />
                <img style={styles.imgTerm} alt="term3" src={require('../../assets/term3.png')} />
                <img style={styles.imgTerm} alt="term4" src={require('../../assets/term4.png')} />
            </article>
        </Wrapper>
    );
};

const styles = {
    root: {
        margin: '2rem 1rem'
    },
    imgTerm: {
        display: 'block',
        width: '100%'
    }
};

export default Terms;