import * as types from './types/dialog';

export const openDialog = (dialogTitle, xmlFunc, width) => async (dispatch) => {
    await dispatch({
        type: types.OPEN_DIALOG,
        payload: {
            dialogTitle,
            xmlFunc,
            width
        }
    });
};

export const closeDialog = () => async (dispatch) => {
    dispatch({ type: types.CLOSE_DIALOG })
};