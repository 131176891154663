import React, { useState, useRef, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import {
    AiOutlineClose
} from 'react-icons/ai';
import {
    GoChevronDown,
    GoChevronUp
} from 'react-icons/go';
// import Calendar from 'react-calendar';
import moment from 'moment';

// utils
import Constants from '../../../utils/Constants';

const CustomDropdown = (props) => {
    const [hoveredOption, setHoveredOption] = useState(-1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { type, name, placeholder, options, style, handleChange, isSearchable, isPredefined, predefinedValue, setDefault } = props;
    const [input, setInput] = useState({
        name: '',
        value: '',
        _id: '',
        color: ''
    });
    const wrapperRef = useRef();
    const [copyOptions, setCopyOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState();

    const showOptionHandler = (i) => {
        setHoveredOption(i);
    };

    const hideOptionHandler = () => {
        setHoveredOption(-1)
    };

    // watches if it's predefined and has predefined values
    // this is if we want an option already selected
    useEffect(() => {
        if (isPredefined && predefinedValue) {
            switch (type) {
                // case 'storyState':
                //     if (isEmpty(options)) return;
                //     let stateObj = options.find((a) => a.name === predefinedValue);
                //     handleValueChange(stateObj);
                //     break;
                // case 'points':
                //     handleValueChange({ value: predefinedValue });
                //     break;
                // case 'calendar':
                //     setDefaultValue(new Date(predefinedValue));
                //     break;
                case 'lockers':
                    console.log('type being called in useEffect')
                    let foundLockerObj = options.find((a) => a.name === predefinedValue);
                    handleValueChange({ name: foundLockerObj.name, value: foundLockerObj.value });
                    break;
                default: return;
            }
        }
    }, []);

    // watches for the input state to change and calls back to parent component with new value
    useEffect(() => {
        handleChange(input);
    }, [input]);

    // watches and assigns the combined repos and filters for if user is searching
    useEffect(() => {
        let optionChoiceReturns = handleOptionChoiceReturn();
        if (!input.name) {
            setCopyOptions(optionChoiceReturns);
        }
        else if (input.name && isSearchable) {
            setCopyOptions(optionChoiceReturns.filter((a) => a.name.toLowerCase().includes(input.name.toLowerCase())));
        }
    }, [input]);

    // watch if the clear form function is being called and setting inputs to default
    useEffect(() => {
        if (setDefault) {
            setInput({
                name: '',
                value: '',
                _id: '',
                color: ''
            });
        }
    }, [setDefault]);

    // returns the display name in each option
    const handleNameReturn = (option) => {
        if (!isEmpty(options)) {
            return option.name;
        }
    };

    // sets the input value when an option is selected
    const handleValueChange = (option) => {
        setInput({
            ...input,
            name: option.name,
            value: option.value
        });
        // switch (type) {
        //     case 'securityQuestions':
        //         setInput({
        //             ...input,
        //             name: option.name,
        //             value: option.value
        //         });
        //         break;
        //     case 'lockers':
        //         setInput({
        //             ...input,
        //             name: option.name,
        //             value: option.value
        //         });
        //         break;
        //     case 'reportTypes':
        //         setInput({
                    
        //         })
        //     default: return null;
        // }
    };

    // checks if there are options passed in through props. 
    const handleOptionChoiceReturn = () => {
        if (isEmpty(options)) {
            return null;
        }
        return options;
    };

    const renderOptions = () => {
        if (!isEmpty(copyOptions)) {
            return Object.values(copyOptions).map((option, i) => {
                return (
                    <button
                        key={i + 1}
                        onMouseEnter={() => showOptionHandler(i)}
                        onMouseLeave={hideOptionHandler}
                        style={{
                            ...styles.option,
                            backgroundColor: hoveredOption === i ? Constants.secondary : 'transparent',
                            color: hoveredOption === i ? 'black' : 'white'
                        }}
                        onClick={(e) => {
                            setIsDropdownOpen(false);
                            handleValueChange(option);
                        }}
                        type="button"
                    >
                        {handleNameReturn(option)}
                    </button>
                );
            });
        }
        return null;
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const renderDropdown = () => {
        if (isDropdownOpen) {
            return (
                <div ref={wrapperRef} className="large-shadow large-rad" style={styles.dropdownBox}>
                    {renderOptions()}
                </div>
            );
        }
        return null;
    };

    function useOutsideAlerter(ref, setIsDropdownOpen) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                // validate that we aren't clicking on the input element. 
                // this is so that the on click event works for that dropdown input el
                if (event.target.id !== type) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setIsDropdownOpen(false)
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, setIsDropdownOpen]);
    }

    useOutsideAlerter(wrapperRef, setIsDropdownOpen);

    // renders the clear icon 
    const renderClearIcon = () => {
        // for the point system we need to check if user inserts a zero
        if (input.name || input.name === 0) {
            return <AiOutlineClose onClick={clearData} style={styles.icons} />
        }
        if (!input.name) {
            return null;
        }
    };

    // render the arrows according to open and closed dropdown
    const renderArrowIcons = () => {
        if (input.name || input.name === 0) {
            return null;
        }
        else if (!isDropdownOpen) {
            return <GoChevronDown onClick={toggleDropdown} style={styles.icons} />
        } else if (isDropdownOpen) {
            return <GoChevronUp onClick={toggleDropdown} style={styles.icons} />
        }
    };

    const clearData = () => {
        setInput({
            name: '',
            value: '',
            _id: '',
            color: ''
        });
    };

    const styles = {
        input: {
            position: 'relative',
            width: '100%',
            padding: '.3rem',
            fontSize: '1rem',
            color: 'black',
            border: '1px solid',
            borderColor: 'white',
            // margin: '1rem 0',
            outline: 'none',
            backgroundColor: 'white'
        },
        dropdownBox: {
            backgroundColor: Constants.cardBg,
            opacity: '.95',
            width: '100%',
            position: 'absolute',
            height: '13rem',
            overflowY: type === 'calendar' ? null : 'scroll',
            padding: 0,
            margin: 0,
            zIndex: '99'
        },
        option: {
            color: 'white',
            fontWeight: 500,
            // margin: '.6rem 0',
            display: 'block',
            width: '100%',
            fontSize: '.9rem',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: '.8rem .5rem',
            textAlign: 'left',
            WebkitTransition: 'all .3s ease',
            MozTransition: 'all .3s ease',
            OTransition: 'all .3s ease',
            transition: 'all .3s ease',
            borderBottom: '1px solid white'
        },
        icons: {
            position: 'absolute',
            top: '50%',
            right: '.2rem',
            WebkitTransform: 'translateY(-50%)',
            MozTransform: 'translateY(-50%)',
            MsTransform: 'translateY(-50%)',
            OTransform: 'translateY(-50%)',
            transform: 'translateY(-50%)',
            color: 'black',
            cursor: 'pointer',
            fontSize: '1.2rem',
            fontWeight: 800
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <input
                id={type}
                readOnly={isSearchable ? false : true}
                onChange={(e) => setInput({ name: e.target.value })}
                value={typeof (defaultValue) === 'object' ? moment(defaultValue).format('ll') : input.name}
                placeholder={placeholder}
                name={name}
                style={{ ...styles.input, ...style }}
                className="small-rad"
                onClick={toggleDropdown}
                autoComplete="off"
                type="text"
            />
            {renderClearIcon()}
            {renderArrowIcons()}
            {renderDropdown()}
        </div>
    );
};


export default CustomDropdown;
