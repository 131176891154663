import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import {
    openDialog
} from '../../../store/actions/dialog';

// utils
import Constants from '../../../utils/Constants';
import { encrypt } from '../../../utils/crypto';

// components
import ToolTip from '../../Layout/Tooltip/Tooltip';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Divider from '../../Layout/Divider/Divider';
import Iconify from '../../Layout/Iconify/Iconify';
import EditLocker from '../EditLocker/EditLocker';

const LockerItem = (props) => {
    const { history, locker } = props;
    const { _id, userId, lockerName, createdAt, updatedAt } = locker;
    const [onHover, setOnHover] = useState(false);
    const dispatch = useDispatch();

    const mouseEnter = () => {
        setOnHover(true);
    };

    const mouseLeave = () => {
        setOnHover(false);
    };
    const onCardClick = (e) => {
        history.push(`/locker/${encrypt(_id)}`);
    };

    const renderEditLocker = (closeDialog) => <EditLocker closeDialog={closeDialog} lockerName={lockerName} lockerId={_id} />

    const onEditClick = (e) => {
        e.stopPropagation();
        dispatch(openDialog(`Editing The ${lockerName} Locker`, renderEditLocker, 30))
    };

    const styles = {
        lockerCard: {
            width: '100%',
            margin: '4rem 0',
            padding: '.8rem',
            cursor: 'pointer',
            WebkitTransition: 'all .4s ease',
            MozTransition: 'all .4s ease',
            OTransition: 'all .4s ease',
            transition: 'all .4s ease',
            boxShadow: onHover && 'none',
            backgroundColor: onHover ? (Constants.main + 'CC') : Constants.main
        },
        lockerName: {
            margin: 0,
            padding: 0,
            fontSize: '1.8rem'
        },
        dateLabel: {
            margin: 0,
            padding: 0,
            fontWeight: 700,
            fontSize: '1.1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            maxWidth: '20rem',
        },
        dateText: {
            fontWeight: 400,
            color: 'white',
            fontSize: '.9rem',
            opacity: '.93'
        },
        editIcon: {
            color: Constants.secondary,
            fontSize: '1.4rem'
        }
    };

    return (
        <main
            onClick={(e) => onCardClick(e)}
            className="large-rad large-shadow"
            style={styles.lockerCard}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <h2 style={styles.lockerName} className="light-text">{lockerName}</h2>
                <ToolTip text="Created Date">
                    <Moment style={styles.dateText} format="ll">{createdAt}</Moment>
                </ToolTip>
            </Wrapper>
            <Divider style={{ margin: '.8rem 0' }} />
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <p className="light-text" style={styles.dateLabel}>Last Changed:
                    <Moment style={{ ...styles.dateText, margin: '0 .4rem' }} format="MM/D/YY  h:m a">
                        {updatedAt}
                    </Moment>
                </p>
                <div onClick={(e) => onEditClick(e)}>
                    <ToolTip name="edit" text={`Edit ${lockerName}`}>
                        <Iconify iconName="edit" style={styles.editIcon} />
                    </ToolTip>
                </div>
            </Wrapper>
        </main>
    );
};


export default withRouter(LockerItem);