import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';

// components
import Input from '../Layout/Input/Input';
import PasswordInput from '../Layout/PasswordInput/PasswordInput';
import Wrapper from '../Layout/Wrapper/Wrapper';
import Button from '../Layout/Button/Button';
import PasswordRules from '../PasswordRules/PasswordRules';
import CustomDropdown from '../Layout/CustomDropdown/CustomDropdown';

// utils
import Constants from '../../utils/Constants';

const Register = (props) => {
    const { register, authLoading, history, setAlert } = props;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        passwordVerify: '',
        securityQuestion: '',
        answer: ''
    });
    const dispatch = useDispatch();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const { name, email, password, passwordVerify, answer, securityQuestion } = formData;

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!handleFormValidation()) {
            await dispatch(setAlert('Please make sure you have completed every field', 'error'));
            return;
        }
        if (!handlePassValidation()) {
            await dispatch(setAlert('Please make sure you complete all the password requirements', 'error'));
            return;
        }
        await dispatch(register(formData, history));
    };

    const handlePassValidation = () => {
        let isValid = true;
        let objectKeys = {
            1: password.length >= 6,
            2: /[a-z]/.test(password),
            3: /[0-9]/.test(password),
            4: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
            5: password === passwordVerify,
            6: /[A-Z]/.test(password)
        };

        for (let val in objectKeys) {
            if (!objectKeys[val]) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const handleFormValidation = () => {
        let isValid = true;
        if (isEmpty(name) || isEmpty(email) || isEmpty(securityQuestion) || isEmpty(answer)) {
            isValid = false;
        }
        return isValid;
    };

    const onQuestionChange = (option) => {
        setFormData({
            ...formData,
            securityQuestion: option.value
        });
    };

    // on click handler when a user wants to view the T&C
    const onTermsClick = () => {
        history.push('/terms&conditions');
    };

    return (
        <>
            <Wrapper styles={{ width: '100%', padding: 0, margin: 0, justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <form style={styles.form}>
                    <div className="form-block">
                        <label className="input-label light-text">Full Name</label>
                        <Input inputType="input" name="name" value={name} onChange={(e) => onChange(e)} placeholder="Full Name" />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Email</label>
                        <Input inputType="input" name="email" value={email} onChange={(e) => onChange(e)} placeholder="email@covertlocker.monster" />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Security Question</label>
                        <CustomDropdown
                            type="securityQuestions"
                            handleChange={onQuestionChange}
                            isSearchable={false}
                            isPredefined={false}
                            options={Constants.securityQuestions}
                            placeholder="Select a security question"
                        />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Answer</label>
                        <PasswordInput viewable name="answer" value={answer} onChange={(e) => onChange(e)} placeholder="Your Answer" />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Password</label>
                        <PasswordInput name="password" value={password} onChange={(e) => onChange(e)} placeholder="Your Password" />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Re-Type Password</label>
                        <PasswordInput name="passwordVerify" value={passwordVerify} onChange={(e) => onChange(e)} placeholder="Re-Type Password" />
                    </div>
                </form>
                <PasswordRules passwordVerify={passwordVerify} password={password} />
            </Wrapper>
            <Wrapper>
                <Button onClick={(e) => onSubmit(e)} style={{ width: '100%', maxWidth: '10rem'}} type="submit" loading={authLoading}>Register</Button>
            </Wrapper>
            <p className="light-text" style={styles.termsText}>Registering means you agree to our <span style={styles.termsLink} onClick={onTermsClick}>Terms & Conditions</span></p>
        </>
    );
};

const styles = {
    form: {
        width: '100%',
        maxWidth: '23rem'
    },
    termsText: {
        opacity: '.6',
        fontSize: '.7rem',
        textAlign: 'center',
        margin: 0,
        padding: 0
    },
    termsLink: {
        fontWeight: 700,
        cursor: 'pointer',
    }
};
export default Register;