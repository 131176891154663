import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { v4 as uuidv4 } from 'uuid';

// utils

// components
import Input from '../../Layout/Input/Input';
import Button from '../../Layout/Button/Button';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Iconify from '../../Layout/Iconify/Iconify';
import BulletItem from '../BulletItem/BulletItem';

const BulletCreator = (props) => {
    const { setDefault, onBulletChange, predefinedBullets } = props;
    const [bullets, setBullets] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        text: '',
        _id: ''
    });
    const [didSubmit, setDidSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const { title, text } = formData;

    // watch and see if there are predefined bullets. if there are then we can just add them to the bullets array
    useEffect(() => {
        if (!isEmpty(predefinedBullets)) {
            console.log(predefinedBullets);
            setBullets([...predefinedBullets]);
        }
    }, []);
    // watch for any change that happens with the bullets array. 
    // if there is a change we update the parent component
    useEffect(() => {
        onBulletChange(bullets);
    }, [bullets]);

    // watch for form submission and setting inputs to default
    useEffect(() => {
        if (setDefault) {
            setFormData({
                title: '',
                text: '',
                id: ''
            });
            setBullets([]);
        }
    }, [setDefault]);
    
    // checks if user presses enter and if so then we submit and add the bullet to the array
    const onKeyPress = (e) => {
        let key = e.code || e.key;
        if (key === 'Enter') {
            addBulletHandler();
        }
    };

    const addBulletHandler = () => {
        setDidSubmit(true);
        if (!title || !text) {
            return;
        }
        if (isEdit) {
            // check if we find an id and if we do in the bullets array with either replace that object with updated or return old one
            setBullets([...bullets.map((obj) => obj._id === formData._id ? formData : obj)]);
        } else {
            setBullets([{ title, text, _id: uuidv4()}, ...bullets]);
        }
        setDidSubmit(false);
        setIsEdit(false);
        clearData();
    };

    const handleError = (val) => {
        if (didSubmit && isEmpty(val)) {
            return true;
        }
        return false
    };

    // empties the inputs after successful submission
    const clearData = () => {
        setFormData({
            title: '',
            text: '',
            _id: ''
        });
    };

    const renderBullets = () => {
        if (isEmpty(bullets)) {
            return null;
        }
        return Object.values(bullets).map((bullet, i) => {
            return <BulletItem onEdit={onEditSelect} onDelete={onDeleteBullet} bullet={bullet} key={i + 1} />
        });
    };

    const onDeleteBullet = (id) => {
        console.log(id)
        setBullets([...bullets.filter((b) => b._id !== id)]);
    };

    const onEditSelect = (id) => {
        setIsEdit(true);
        let foundBullet = bullets.filter((a) => a._id === id)[0];
        setFormData({
            title: foundBullet.title,
            text: foundBullet.text,
            _id: id
        });
    };

    const styles = {
        titleInput: {
            maxWidth: '15rem',
            borderColor: handleError(title) ? 'red' : 'white'
        },
        textInput: {
            marginTop: '1rem',
            borderColor: handleError(text) ? 'red' : 'white'
        },
        // where the created bullets are rendered
        bulletContainer: {

        }
    };

    return (
        <>
            <Input
                style={styles.titleInput}
                inputType="input"
                placeholder="Bullet Title"
                value={title}
                name="title"
                onChange={(e) => onChange(e)}
                onKeyPress={(e) => onKeyPress(e)}
            />
            <Input
                placeholder="Encrypted text..."
                inputType="textarea"
                rows="2"
                style={styles.textInput}
                value={text}
                name="text"
                onChange={(e) => onChange(e)}
            />
            <Wrapper styles={{ justifyContent: 'flex-end' }}>
                <Button type="button" onClick={addBulletHandler}><Iconify iconName={!isEdit ? 'add' : 'save' } /></Button>
            </Wrapper>
            <main style={styles.bulletContainer}>
                {renderBullets()}
            </main>
        </>
    );
};



export default BulletCreator;