import * as types from './types/report';
import { isEmpty } from 'jvh-is-empty';
import { setAlert } from './alert';
import Constants from '../../utils/Constants';
import axios from 'axios';

const PROD_URL = Constants.PROD_URL;

//! Create a report
export const createReport = (formData) => async (dispatch) => {
    if (isEmpty(formData.reportType) || isEmpty(formData.message)) {
        return await dispatch(setAlert('Please fill out each field', 'error'));
    }
    await dispatch({ type: types.CREATE_REPORT_PENDING });
    await dispatch(createReportReq({ ...formData }));
};

export const createReportReq = (formData) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/report/create_report`, formData, config)
    .then(async (res) => {
        await dispatch({ type: types.CREATE_REPORT_SUCCESS });
        await dispatch(setAlert(res.data.serverMsg, 'success'));
    }).catch(async (err) => {
        const error = err.response.data.serverMsg;
        if (error) {
            await dispatch(setAlert(error, 'error'));
        }
        await dispatch({
            type: types.CREATE_REPORT_FAIL
        });
    });
};