import * as types from './types/padlockFilter';

export const inputPadlockFilter = (padlocks) => (dispatch) => {
    dispatch({
        type: types.INPUT_PADLOCK_SUCCESS,
        payload: padlocks
    });
};

export const clearPadlockFilter = (padlocks) => (dispatch) => {
    dispatch({
        type: types.CLEAR_PADLOCK_FILTER,
        payload: padlocks
    });
}