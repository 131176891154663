import React from 'react';
import { removeAlert } from '../../../store/actions/alert';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';

// styles
import './alert.css';

// components
import Constants from '../../../utils/Constants';
import Iconify from '../Iconify/Iconify';
import Wrapper from '../Wrapper/Wrapper';

const Alert = (props) => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => state.alert);

    // returns the header color, name type, and icon
    const returnHeaderColorName = (alertType, valueReturn) => {
        let objReturned = {
            icon: null,
            name: '',
            color: ''
        };
        switch (alertType) {
            case 'success':
                objReturned.icon = <Iconify iconName="success" style={{ ...styles.alertIcon }} />
                objReturned.name = 'Success';
                objReturned.color = '#54D700';
                return objReturned[valueReturn]
            case 'error':
                objReturned.icon = <Iconify iconName="error" style={{ ...styles.alertIcon }} />
                objReturned.name = 'Error';
                objReturned.color = '#D70000';
                return objReturned[valueReturn]
            default: return objReturned;
        }
    };

    const styles = {
        alertRoot: {
            overflow: 'hidden',
        },
        alertComponent: {
            position: 'fixed',
            top: '1rem',
            right: '1rem',
            width: '95%',
            maxWidth: '13rem',
            backgroundColor: Constants.lightGrey,
            zIndex: 9999999,
            overflow: 'hidden',
            WebkitAnimationDuration: '5s',
            WebkitAnimationIterationCount: 1,
            WebkitAnimationFillMode: 'both',
            WebkitAnimationName: 'alert-in',
            MozAnimationDuration: '5s',
            MozAnimationIterationCount: 1,
            MozAnimationFillMode: 'both',
            MozAnimationName: 'alert-in',
            MsAnimationDuration: '5s',
            MsAnimationIterationCount: 1,
            MsAnimationFillMode: 'both',
            MsAnimationName: 'alert-in',
            animationDuration: '5s',
            animationIterationCount: 1,
            animationFillMode: 'both',
            animationName: 'alert-in'
        },
        alertHead: {
            backgroundColor: Constants.secondary,
            padding: '.5rem',
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
        },
        // alert name type
        typeName: {
            margin: 0,
            padding: 0,
            color: 'black',
            fontSize: '1rem'
        },
        alertIcon: {
            fontSize: '1.5rem',
            color: 'black'
        },
        alertBody: {
            height: 'auto',
            minHeight: '3rem',
            padding: '.3rem .5rem'
        },
        alertMsg: {
            fontSize: '.8rem',
            fontWeight: 600,
            margin: 0,
            padding: 0,
            width: '100%',
            textAlign: 'left'
        },
        alertFooter: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        closeIcon: {
            color: Constants.secondary,
            cursor: 'pointer',
            fontSize: '1.3rem',
            margin: '.2rem .5rem'
        },
    };

    return !isEmpty(alerts) && (
        <div id="alertStyles_root" style={styles.alertRoot}>
            {alerts.map((alert) => (
                <div key={alert.id} className="large-rad large-shadow alertComponent" style={styles.alertComponent}>
                    <div style={{ ...styles.alertHead }}>
                        <Wrapper styles={{ justifyContent: 'space-between' }}>
                            <h6 style={styles.typeName}>
                                {
                                    returnHeaderColorName(alert.alertType, 'name')
                                }
                            </h6>
                            {returnHeaderColorName(alert.alertType, 'icon')}
                        </Wrapper>
                    </div>
                    <div style={styles.alertBody}>
                        <p className="light-text" style={styles.alertMsg}>
                            {alert.msg}
                        </p>
                    </div>
                    <div style={styles.alertFooter}>
                        <span onClick={() => dispatch(removeAlert(alert.id))}><Iconify iconName="close" style={styles.closeIcon} /></span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Alert;