import React, { useEffect, useState } from 'react';

import './tooltip.css';

// utils
import Constants from '../../../utils/Constants';

const Tooltip = (props) => {
    const { } = props;
    const [coord, setCoord] = useState({
        x: 0,
        y: 0
    });
    const [hoveredTool, setHoveredTool] = useState(false);
    const [renderTip, setRenderTip] = useState(false);

    // need to use timeout effect on this will mount 
    useEffect(() => {
        // if user is using a mobile device or anything of the sort.
        // turn off the tooltips
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return;
        }
        let timeout;
        if (hoveredTool) {
            timeout = setTimeout(() => {
                setRenderTip(true);
            }, 1000)
        } else if (!hoveredTool) {
            clearTimeout(timeout);
            setRenderTip(false);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [hoveredTool]);

    const showHoveredTool = () => {
        setHoveredTool(true);
    };

    const hideHoveredTool = () => {
        setHoveredTool(false)
    };

    const onMouseMove = (e) => {        
        setCoord({
            x: e.pageX,
            y: e.pageY - (window.pageYOffset + 25)
        });
    };

    const { x, y } = coord;

    const styles = {
        tooltip: {
            display: renderTip ? 'block' : 'none',
            position: 'fixed',
            left: `${x}px`,
            top: `${y}px`,
            WebkitTransform: 'translate(-50%, -50%)',
            MozTransform: 'translate(-50%, -50%)',
            OTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
            width: '10rem',
            backgroundColor: Constants.tooltipBg,
            pointerEvents: 'none',
            padding: '.3rem 1rem',
            borderRadius: '4px',
            textAlign: 'center',
            fontSize: '.8rem',
            fontWeight: 500
        }
    };

    return (
        <div
            onMouseMove={(e) => onMouseMove(e)}
            onMouseEnter={showHoveredTool}
            onMouseLeave={hideHoveredTool}
            style={{ ...props.style }}
            onClick={props.onClick}
        >
            <div id="tooltip" className="light-text" style={styles.tooltip}>{props.text}</div>
            {props.children}
        </div>
    );
};

export default Tooltip;