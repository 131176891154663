import React from 'react';

const LostPage = () => {
    return (
        <article style={styles.root}>
            <p style={styles.text}>Uh oh! It looks like you're lost!</p>
        </article>
    );
};

const styles = {
    root: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MozTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    },
    text: {
        color: 'white',
        fontSize: '1.4rem'
    }
};

export default LostPage;