import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';
import Moment from 'react-moment';
import {
    createCode
} from '../../store/actions/code';
import {
    updateUser
} from '../../store/actions/auth';

// utils
import Constants from '../../utils/Constants';

// components
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import Divider from '../../components/Layout/Divider/Divider';
import Iconify from '../../components/Layout/Iconify/Iconify';
import UserSettings from '../../components/UserSettings/UserSettings';
import Button from '../../components/Layout/Button/Button';
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import Input from '../../components/Layout/Input/Input';
import MiniLoader from '../../components/Layout/MiniLoader/MiniLoader';

const Account = (props) => {
    const { authLoading, user, isAuth, updatingProfile } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { creating } = useSelector((state) => state.code);
    const [selectedField, setSelectedField] = useState(null);
    const fieldLayout = {
        0: 'email',
        1: 'security',
        2: 'password',
        3: 'name'
    };
    const [nameEditing, setNameEditing] = useState(false);
    const [nameStr, setNameStr] = useState('');
    const [renderMobileView, setRenderMobileView] = useState(false);

    useEffect(() => {

        const handleRules = () => {
            if (window.innerHeight < 766 || window.innerWidth < 619) {
                setRenderMobileView(true);
            } else {
                setRenderMobileView(false);
            }
        };
        window.addEventListener('resize', handleRules)
        return () => window.removeEventListener('resize', handleRules);
    }, []);

    const userSettingForm = (closeDialog, settingType) => <UserSettings settingType={settingType} closeDialog={closeDialog} />;

    useEffect(() => {
        if (!authLoading && !isEmpty(user)) {
            setNameStr(user.name);
        }
    }, [authLoading, user]);

    useEffect(() => {
        if (!creating) {
            setSelectedField(null);
        }
    }, [creating]);

    const onChangeClick = (id) => {
        setSelectedField(id);
        dispatch(createCode(userSettingForm, fieldLayout[id]));
    };

    const renderEmail = () => {
        if (!authLoading && isAuth && !isEmpty(user)) {
            let email = user.email;
            let splitEmail = email.split('@');
            let domain = splitEmail[1];
            let name = splitEmail[0][0];
            return `${name}*******@${domain}`;
        }
    };

    const onNameChangeSubmit = async () => {
        setNameEditing(false);
        // if no changes are made we stop the process
        if (nameStr === user.name) {
            return;
        }
        let formData = {
            name: nameStr
        };
        await dispatch(updateUser(formData));
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            onNameChangeSubmit();
        }
    };

    const styles = {
        root: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            WebkitTransform: 'translate(-50%, -50%)',
            MozTransform: 'translate(-50%, -50%)',
            OTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
            padding: '1.5rem',
            width: '90%',
            maxWidth: '40rem',
            backgroundColor: Constants.main,
        },
        // wrapper surrounding all user data
        dataWrap: {

        },
        // wrapper around the header of the card
        headerWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexFlow: 'row wrap',
            height: 'auto',
            minHeight: '4rem'
        },
        // name of user
        userName: {
            margin: 0,
            padding: 0,
            fontSize: '2rem',
            fontWeight: 700,

        },
        settingIcon: {
            color: Constants.secondary,
            cursor: 'pointer',
            fontSize: '1.4rem'
        },
        // frame containing each user data piece
        dataFrame: {
            margin: '0 0 1.5rem 0'
        },
        // label for each data card
        dataLabel: {
            fontSize: '1.4rem',
            margin: 0,
            padding: 0,
            margin: '0 0 .6rem 0'
        },
        // data card 
        dataCard: {
            width: '100%',
            padding: '.5rem .5rem',
            backgroundColor: Constants.bodyBg,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexFlow: 'row wrap'
        },
        //text in each card
        dataText: {
            padding: 0,
            margin: 0,
        },
        // member since text
        memberText: {
            textAlign: 'center',
            padding: 0,
            margin: '2rem 0 0 0',
            fontSize: '.9rem',
            fontWeight: 500
        },
        // change buttons 
        changeBtn: {
            margin: 0
        },
        // edit name icon
        editIcon: {
            color: Constants.secondary,
            fontSize: '1.4rem',
            cursor: 'pointer'
        },
        nameInput: {
            width: '100%',
            maxWidth: '26rem',
            backgroundColor: 'rgba(0,0,0,.3)',
            border: 'none',
            borderColor: 'transparent',
            color: 'white',
            caretColor: Constants.secondary,
            outline: 'none',
            padding: '.4rem .2rem',
            margin: 0,
            fontSize: '2rem',
            fontWeight: 700,
            borderRadius: '4px',
            // height: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        // icon for the hidden password
        lockIcon: {
            color: Constants.secondary,
            margin: '0 .8rem 0 0'
        }
    };

    const renderChngBtns = (key) => {
        if (renderMobileView) {
            return (
                <Wrapper styles={{ justifyContent: 'flex-end' }}>
                    <Button onClick={() => {
                        onChangeClick(key);
                    }} type="button" style={styles.changeBtn} loading={creating && selectedField === 0}>Change</Button>
                </Wrapper>
            )
        } else if (!renderMobileView) {
            return (
                <Button onClick={() => {
                    onChangeClick(key);
                }} type="button" style={styles.changeBtn} loading={creating && selectedField === 0}>Change</Button>

            )
        }
    };

    const renderUserData = () => {
        return !authLoading && isAuth && !isEmpty(user) && (
            <section style={styles.dataWrap}>
                <div style={styles.headerWrap}>
                    {!nameEditing ?
                        <p className="light-text" style={styles.userName}>{user.name}</p>
                        :
                        <input
                            type="text"
                            value={nameStr}
                            onChange={(e) => setNameStr(e.target.value)}
                            style={styles.nameInput}
                            autoFocus
                            onKeyDown={(e) => onKeyDown(e)}
                        />
                    }
                    <span onClick={() => {
                        if (!nameEditing) {
                            setNameEditing(true);
                        } else {
                            onNameChangeSubmit();
                            setNameEditing(false);
                        }
                    }}>
                        {!updatingProfile ?
                            <Iconify iconName={nameEditing ? 'save' : 'edit'} style={styles.editIcon} />
                            :
                            <MiniLoader />
                        }
                    </span>
                </div>
                <Divider style={{ margin: '1rem 0' }} />
                <main style={styles.dataFrame}>
                    <h5 className="light-text" style={styles.dataLabel}>Email</h5>
                    <div className="large-rad large-shadow" style={styles.dataCard}>
                        <p className="light-text" style={styles.dataText}>{renderEmail()}</p>
                        {renderChngBtns(0)}
                    </div>
                </main>
                <main style={styles.dataFrame}>
                    <h5 className="light-text" style={styles.dataLabel}>Security Question</h5>
                    <div className="large-rad large-shadow" style={styles.dataCard}>
                        <p className="light-text" style={styles.dataText}>{user.security.question}</p>
                        {renderChngBtns(1)}
                    </div>
                </main>
                <main style={styles.dataFrame}>
                    <h5 className="light-text" style={styles.dataLabel}>Password</h5>
                    <div className="large-rad large-shadow" style={styles.dataCard}>
                        <p className="light-text" style={styles.dataText}>{[...Array(renderMobileView ? 3 : 7)].map((_, i) => <Iconify key={i} iconName="lock" style={styles.lockIcon} />)}</p>
                        {renderChngBtns(2)}
                    </div>
                </main>
                <p className="light-text" style={styles.memberText}>Member since <Moment format="ll">{user.date}</Moment></p>
            </section>
        );
    };

    return (
        <article className="large-rad large-shadow" style={styles.root}>
            {authLoading && <Wrapper>
                <PageLoader />
            </Wrapper>}
            {renderUserData()}
        </article>
    );
};

export default Account;