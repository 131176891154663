import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';
import {
    fetchLockers
} from '../../store/actions/locker';

// utils

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import LockerItem from '../../components/Lockers/LockerItem/LockerItem';
import LockerFilterBar from '../../components/Lockers/LockerFilterBar/LockerFilterBar';

const Locker = (props) => {
    const { fetching, updating, deleting, locker, creating, lockers } = useSelector((state) => state.locker);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!fetching && isEmpty(lockers)) {
            dispatch(fetchLockers());
        } 
    }, []);

    const renderLockers = () => {
        if (isEmpty(lockers)) {
            return null;
        }
        return Object.values(lockers).map((locker, i) => {
            return <LockerItem key={i + 1} locker={locker} />
        });
    };

    const renderFilterBar = () => {
        if (isEmpty(lockers)) {
            return null;
        }
        return (
            <div style={styles.lockerFilterBarContainer}>
                <LockerFilterBar creating={creating} lockers={lockers} />
            </div>
        );
    };

    return (
        <article style={styles.root}>
            {fetching && (
                <Wrapper>
                    <PageLoader />
                </Wrapper>
            )}
            {!fetching && isEmpty(lockers) && <h4 className="light-text" style={styles.emptyMsg}>Your lockers are lost! Try creating some.</h4>}
            <Wrapper>
                {/* {renderFilterBar()} */}
            </Wrapper>
            <Wrapper styles={{ width: '100%', maxWidth: '100%' }}>
                    <div style={styles.lockerCardContainer}>
                        {renderLockers()}
                    </div>
            </Wrapper>
        </article>
    );
};

const styles = {
    root: {
        marginBottom: '3rem'
    },
    // empty messsage when no lockers are found
    emptyMsg: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MozTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        fontSize: '1.3rem'
    },
    // container that wraps around the locker cards to center all of them in block display
    lockerCardContainer: {
        width: '90%',
        maxWidth: '35rem',
    },
    // container around the filter bar
    lockerFilterBarContainer: {
        width: '100%',
        maxWidth: '50rem'
    }
};

export default Locker;