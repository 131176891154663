export default [
    {
        name: 1,
        value: 1
    },
    {
        name: 2,
        value: 2
    },
    {
        name: 3,
        value: 3
    }
];