import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

// utils
import Constants from '../../utils/Constants';

// components

const Countdown = (props) => {
    return (
        <CountdownCircleTimer
            isPlaying
            duration={299}
            colors={Constants.secondary}
            trailColor={Constants.lightGrey}
            size={70}
            strokeWidth={5}
        >
            {({ remainingTime }) => {
                const minutes = Math.floor((remainingTime % 3600) / 60);
                let seconds = remainingTime % 60;
                if (seconds < 10) {
                    seconds = `0${seconds}`
                }
                return <span style={{ color: 'white' }}>{minutes}:{seconds}</span>
            }}
        </CountdownCircleTimer>
    )
};

export default Countdown;