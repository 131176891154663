import * as types from '../actions/types/report';

const initialState = {
    isSending: false
};

const dialogReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case types.CREATE_REPORT_FAIL:
        case types.CREATE_REPORT_SUCCESS:
            return {
                ...state,
                isSending: false
            };
        case types.CREATE_REPORT_PENDING:
            return {
                ...state,
                isSending: true
            };
        default: return state;
    };
}

export default dialogReducer;