import React from 'react';

const Input = (props) => {
    if (props.inputType === 'input') {
        return <input
            onKeyUp={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            autoComplete="off"
            className="small-rad"
            style={{ ...styles, ...props.style }}
            type={props.type} name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    } else {
        return <textarea
            onKeyUp={props.onKeyPress}
            className="small-rad"
            style={{ ...styles, ...props.style }}
            rows={props.rows || 4}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onKeyDown={props.onKeyDown}
        ></textarea>
    }
};

const styles = {                    
    width: '100%',
    padding: '.3rem',
    fontSize: '1rem',
    color: 'black',
    border: '1px solid',
    borderColor: 'white',
    // margin: '1rem 0',
    outline: 'none',
    backgroundColor: 'white'
};

export default Input;