import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import confirmModal from './confirmModal';
import locker from './locker';
import dialog from './dialog';
import padlock from './padlock';
import code from './code';
import report from './report';
import backlog from './backlog';

const appReducer = combineReducers({
    alert,
    auth,
    confirmModal,
    locker,
    dialog,
    padlock,
    code,
    report,
    backlog
});

// using this function to reset store to initial state after user logout!
// SOURCE: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducer;