//! DATE TYPE
export const DATE_SUCCESS = 'DATE_SUCCESS';

//! NAME TYPES
export const NAME_SUCCESS = 'NAME_SUCCESS';

//! INPUT TYPES
export const INPUT_SUCCESS = 'INPUT_SUCCESS';

//! CLEAR FILTER
export const CLEAR_FILTER = 'CLEAR_FILTER';
