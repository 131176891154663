import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'jvh-is-empty';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchPadlocks
} from '../../store/actions/padlock';
import {
    openDialog
} from '../../store/actions/dialog';

// utils
import { decrypt } from '../../utils/crypto';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import AnswerSecurity from '../../components/Security/AnswerSecurity/AnswerSecurity';
import PadlockItem from '../../components/Padlocks/PadlockItem/PadlockItem';
import PadlockFilterBar from '../../components/Padlocks/PadlockFilterBar/PadlockFilterBar';

const Padlocks = (props) => {
    const { lockerId } = useParams();
    const dispatch = useDispatch();
    const { fetching, padlocks } = useSelector((state) => state.padlock);
    const { authLoading, isAuth, user } = useSelector((state) => state.auth);
    const [renderMsg, setRenderMsg] = useState(false);

    const securityForm = (closeDialog) => <AnswerSecurity lockerId={decrypt(lockerId)} closeDialog={closeDialog} />;

    useEffect(() => {
        if (!authLoading && isAuth && !isEmpty(user)) {
            if (isEmpty(localStorage.securityQuestion)) {
                return dispatch(openDialog("Stop!", securityForm, 30));
            }
            else if (!isEmpty(localStorage.securityQuestion) && user.security.question !== localStorage.getItem("securityQuestion")) {
                return dispatch(openDialog("Stop!", securityForm, 30));
            }

            if (isEmpty(localStorage.userEmail)) {
                return dispatch(openDialog("Stop!", securityForm, 30));
            } else if (user.email !== decrypt(localStorage.getItem("userEmail"))) {
                return dispatch(openDialog("Stop!", securityForm, 30));
            }

            if (!isEmpty(lockerId) && !fetching) {
                dispatch(fetchPadlocks(decrypt(lockerId)));
                setRenderMsg(true);
            }
        }
    }, [localStorage, authLoading]);

    const renderPadlocks = () => {
        if (!fetching && isEmpty(padlocks)) return null;

        else if (!fetching && !isEmpty(padlocks)) {
            return (
                <div style={styles.padlockContainer}>
                    {Object.values(padlocks).map((pad, i) => {
                        return <PadlockItem padlock={pad} key={i + 1} />
                    })}
                </div>
            );
        }
    };

    return (
        <article style={styles.root}>
            <Wrapper>
                <PadlockFilterBar lockerId={lockerId} />
            </Wrapper>
            {fetching &&
                <section style={styles.loaderWrap}>
                    <PageLoader />
                </section>
            }
            <Wrapper>
                {!fetching && isEmpty(padlocks) && renderMsg && <h2 className="light-text" style={styles.emptyMsg}>Oops! Looks like there were no padlocks found.</h2>}
                {!fetching && !isEmpty(padlocks) &&
                    renderPadlocks()
                }
            </Wrapper>
        </article>
    );
};

const styles = {
    root: {
        marginBottom: '4rem'
    },
    // wrapper for the page loader to render in middle of screen
    loaderWrap: {
        position: 'fixed',
        left: '50%',
        top: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MozTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    },
    // container that surrounds all the padlocks
    padlockContainer: {
        width: '90%',
        maxWidth: '35rem'
    },
    // empty message for when either padlocks do not exist or filtering is empty
    emptyMsg: {
        marginTop: '3rem',
        textAlign: 'center'
    }
};

export default Padlocks;