import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';
import {
    fetchBacklogs
} from '../../store/actions/backlog';

// utils
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import BacklogItem from '../../components/Backlogs/BacklogItem/BacklogItem';

const Backlogs = (props) => {
    const dispatch = useDispatch();
    const { fetching, backlogs } = useSelector((state) => state.backlog);

    useEffect(() => {
        dispatch(fetchBacklogs());
    }, []);

    const renderBacklogs = () => {
        if (fetching) {
            return <PageLoader />
        }
        if (!fetching && isEmpty(backlogs)) {
            return null;
        }
        else if (!fetching && !isEmpty(backlogs)) {
            return Object.values(backlogs).map((backlog, i) => <BacklogItem key={i + 1} backlog={backlog} />);
        }
    };

    return (
        <article style={styles.root}>
            <Wrapper>
                {renderBacklogs()}
            </Wrapper>
        </article>
    );
};

const styles = {
    root: {
        margin: '0 0 3rem 0'
    }
};

export default Backlogs;