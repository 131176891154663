import * as types from '../actions/types/auth';

const initialState = {
    token: localStorage.getItem('token'),
    isAuth: null,
    user: null,
    authLoading: false,
    loggingOut: false,
    updatingProfile: false,
    changingPassword: false,
    isAdmin: false,
    securityLoading: false,
    terminating: false
};

const oktaReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.LOGIN_FAIL:
        case types.LOAD_USER_FAIL:
        case types.LOGOUT_SUCCESS:
        case types.LOGOUT_FAIL:
        case types.SETUP_FAIL:
        case types.REGISTER_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                isAuth: null,
                authLoading: false,
                loggingOut: false
            };
        case types.REGISTER_PENDING:
        case types.LOGIN_PENDING:
        case types.LOAD_USER_PENDING:
        case types.SETUP_PENDING:
            return {
                ...state,
                isAuth: null,
                user: null,
                authLoading: true,
            };
        case types.REGISTER_SUCCESS:
        case types.LOGIN_SUCCESS:
        case types.SETUP_SUCCESS:
            localStorage.setItem('token', payload);
            return {
                ...state,
                authLoading: false,
                isAuth: true,
                token: payload
            };
        case types.LOAD_USER_SUCCESS:
            return {
                ...state,
                isAuth: true,
                authLoading: false,
                user: payload
            };
        case types.LOGOUT_PENDING:
            return {
                ...state,
                loggingOut: true
            };
        case types.UPDATE_USER_FAIL:
        case types.CHANGE_PASSWORD_FAIL:
        case types.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                updatingProfile: false,
                changingPassword: false,
            };
        case types.UPDATE_USER_PENDING:
            return {
                ...state,
                updatingProfile: true
            };
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
                user: payload
            };
        case types.CHANGE_PASSWORD_PENDING:
        case types.FORGOT_PASSWORD_PENDING:
            return {
                ...state,
                changingPassword: true
            };
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false
            };
        case types.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
            };
        case types.CHECK_SECURITY_FAIL:
        case types.CHECK_SECURITY_SUCCESS:
        case types.ENTER_PASSWORD_FAIL:
        case types.ENTER_PASSWORD_SUCCESS:
            return {
                ...state,
                securityLoading: false
            };
        case types.ENTER_PASSWORD_PENDING:
        case types.CHECK_SECURITY_PENDING:
            return {
                ...state,
                securityLoading: true
            };
        case types.TERMINATE_FAIL:
        case types.TERMINATE_SUCCESS:
            return {
                ...state,
                terminating: false
            };
        case types.TERMINATE_PENDING:
            return {
                ...state,
                terminating: true
            };
        default: return state;
    };
};

export default oktaReducer;