import * as types from '../actions/types/dialog';

const initialState = {
    isOpen: false,
    dialogData: {
        dialogTitle: '',
        xmlFunc: () => {},
        width: ''
    }
};

const dialogReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case types.OPEN_DIALOG:
            return {
                ...state,
                isOpen: true,
                dialogData: payload
            };
        case types.CLOSE_DIALOG:
            return initialState;
        default: return state;
    };
}

export default dialogReducer;