import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    terminateUser
} from '../../store/actions/auth';
import { withRouter } from 'react-router-dom';

// utils

// components
import Wrapper from '../Layout/Wrapper/Wrapper';
import Button from '../Layout/Button/Button';
import PasswordInput from '../Layout/PasswordInput/PasswordInput';
import Input from '../Layout/Input/Input';

const TerminateAccount = (props) => {
    const { closeDialog, history } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        password: '',
        reason: ''
    });
    const { terminating } = useSelector((state) => state.auth);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(terminateUser(formData, history));
    };
    
    const { password, reason } = formData;

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <p className="light-text" style={styles.subTitle}>Are you sure?</p>
            <div className="form-block">
                <label className="input-label light-text">Enter Your Password</label>
                <PasswordInput  
                    placeholder="Your password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    name="password"
                />
            </div>
            <div className="form-block">
                <label className="input-label light-text">Your Reason For Leaving</label>
                <Input 
                    inputType="textarea"
                    value={reason}
                    name="reason"
                    onChange={(e) => onChange(e)}
                    placeholder="Why you're leaving CovertLocker..."
                    style={{ marginBottom: '1rem' }}
                />
            </div>
            <p className="light-text" style={styles.msg}>Terminating your account will mean all of your information will be erased and you will not be able to log in</p>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <Button type="button" onClick={() => dispatch(closeDialog())}>Close</Button>
                <Button type="submit" loading={terminating}>Terminate</Button>
            </Wrapper>
        </form>
    );
};

const styles = {
    subTitle: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '1.1rem',
        margin: 0,
        padding: 0
    },
    msg: {
        textAlign: 'center',
        padding: 0,
        margin: '1rem 1rem',
        fontSize: '.8rem',
        opacity: '.7',
        fontWeight: 500
    }
};

export default withRouter(TerminateAccount);