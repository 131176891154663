import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';

// components
import Routing from './components/Routes/Routes';

// redux
import store from './store/store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './store/actions/auth';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const { authLoading, token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!authLoading && !isEmpty(token) && isEmpty(user)) {
      store.dispatch(loadUser());
    }
  }, [authLoading, token, user]);

  return (
      <Router>
        <Route component={Routing} />
      </Router>
  );
};

export default App;