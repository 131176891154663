import { isEmpty } from 'jvh-is-empty';
import * as types from '../actions/types/padlock';
import * as filterTypes from '../actions/types/padlockFilter';

const initialState = {
    fetching: false,
    deleting: false,
    updating: false,
    creating: false,
    padlocks: [],
    padlock: null
};

const confirmModalReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case types.FETCH_PADLOCKS_FAIL:
        case types.FETCH_PADLOCK_FAIL:
            return {
                ...state,
                fetching: false,
                padlocks: [],
                padlock: null
            };
        case types.FETCH_PADLOCKS_PENDING:
            return {
                ...state,
                fetching: true,
                padlocks: [],
                padlock: null
            };
        case types.FETCH_PADLOCKS_SUCCESS:
            return {
                ...state,
                fetching: false,
                padlocks: [...payload]
            };
        case types.CREATE_PADLOCK_FAIL:
            return {
                ...state,
                creating: false
            };
        case types.CREATE_PADLOCK_PENDING:
            return {
                ...state,
                creating: true
            };
        case types.CREATE_PADLOCK_SUCCESS:
            return {
                ...state,
                creating: false
            };
        case types.FETCH_PADLOCK_PENDING:
            return {
                ...state,
                fetching: true,
                padlock: null
            };
        case types.FETCH_PADLOCK_SUCCESS:
            return {
                ...state,
                fetching: false,
                padlock: payload
            };
        case types.UPDATE_PADLOCK_FAIL:
        case types.MOVE_PADLOCKS_FAIL:
            return {
                ...state,
                updating: false,
            };
        case types.UPDATE_PADLOCK_PENDING:
        case types.MOVE_PADLOCKS_PENDING:
            return {
                ...state,
                updating: true
            };
        case types.UPDATE_PADLOCK_SUCCESS:
            let filteredPadlocks;
            if (!isEmpty(state.padlocks)) {
                let updatingIndex = state.padlocks.findIndex((a) => a._id === payload._id);
                if (updatingIndex !== -1) {
                    let foundPad = state.padlocks[updatingIndex]
                    // check to see if the locker location has changed
                    if (foundPad.lockerId !== payload.lockerId) {
                        // if it has then we remove the padlock
                        filteredPadlocks = state.padlocks.filter((a) => a._id !== payload._id);
                    } else {
                        filteredPadlocks = state.padlocks.splice(updatingIndex, 1, payload)
                    }
                }
            }
            return {
                ...state,
                updating: false,
                padlock: payload,
                padlocks: filteredPadlocks
            }
        case types.MOVE_PADLOCKS_SUCCESS:
            // passing in the changing padlocks. if the current traversed padlock is not included then we return it
            return {
                ...state,
                updating: false,
                padlocks: state.padlocks.filter((a) => !JSON.stringify(payload).includes(a._id))
            };
        case filterTypes.INPUT_PADLOCK_SUCCESS:
        case filterTypes.CLEAR_PADLOCK_FILTER:
            return {
                ...state,
                padlocks: [...payload]
            };
        case types.DELETE_PADLOCK_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_PADLOCK_PENDING:
            return {
                ...state,
                deleting: true
            };
        case types.DELETE_PADLOCK_SUCCESS:
            return {
                ...state,
                deleting: false,
                padlock: null, 
                padlocks: state.padlocks.length > 0 && state.padlocks.filter((a) => a._id !== payload)
            };
        
        default: return state;
    }
};

export default confirmModalReducer;