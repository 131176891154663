import * as types from './types/locker';
import axios from 'axios';
import { setAlert } from './alert';
import { closeDialog } from './dialog';
import Constants from '../../utils/Constants';

const PROD_URL = Constants.PROD_URL;

// ! Fetch lockers 
export const fetchLockers = () => async (dispatch) => {
    await dispatch({ type: types.FETCH_LOCKERS_PENDING });
    await dispatch(fetchLockersReq());
};

export const fetchLockersReq = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.get(`${PROD_URL}/api/locker/fetch_lockers`, config)
    .then(async (res) => {
        await dispatch({ 
            type: types.FETCH_LOCKERS_SUCCESS,
            payload: res.data
        });
    }).catch(async (err) => {
        await dispatch({
            type: types.FETCH_LOCKERS_FAIL
        });
    });
};

//! Create locker
export const createLocker = (lockerName) => async (dispatch) => {
    await dispatch({ type: types.CREATE_LOCKER_PENDING });
    await dispatch(createLockerReq(lockerName));
};

export const createLockerReq = (lockerName) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/locker/create_locker`, { lockerName }, config)
    .then(async (res) => {
        await dispatch(closeDialog());
        await dispatch({
            type: types.CREATE_LOCKER_SUCCESS,
            payload: res.data.locker
        });
        await dispatch(setAlert(res.data.serverMsg, 'success'));
    }).catch(async (err) => {
        const error = err.response.data.serverMsg;
        if (error) {
            await dispatch(setAlert(error, 'error'));
        }
        await dispatch({
            type: types.FETCH_LOCKERS_FAIL
        });
    });
};

//! Update locker
export const updateLocker = (lockerName, lockerId) => async (dispatch) => {
    await dispatch({ type: types.UPDATE_LOCKER_PENDING });
    await dispatch(updateLockerReq(lockerName, lockerId));
};

export const updateLockerReq = (lockerName, lockerId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.put(`${PROD_URL}/api/locker/update_locker/${lockerId}`, { lockerName }, config)
    .then(async (res) => {
        await dispatch(closeDialog());
        await dispatch({
            type: types.UPDATE_LOCKER_SUCCESS,
            payload: res.data.locker
        });
        await dispatch(setAlert(res.data.serverMsg, 'success'));
    }).catch(async (err) => {
        const error = err.response.data.serverMsg;
        if (error) {
            await dispatch(setAlert(error, 'error'));
        }
        await dispatch({
            type: types.UPDATE_LOCKER_FAIL
        });
    });
};

//! Delete locker
export const deleteLocker = (formData, lockerId, history) => async (dispatch) => {
    await dispatch({ type: types.DELETE_LOCKER_PENDING });
    await dispatch(deleteLockerReq({ ...formData }, lockerId, history));
};

export const deleteLockerReq = (formData, lockerId, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        },
        data: {
            ...formData
        }
    };

    await axios.delete(`${PROD_URL}/api/locker/delete_locker/${lockerId}`, config)
    .then(async (res) => {
        await dispatch(closeDialog());
        history.push('/locker');
        await dispatch({
            type: types.DELETE_LOCKER_SUCCESS,
            payload: lockerId
        });
        await dispatch(setAlert(res.data.serverMsg, 'success'));
    }).catch(async (err) => {
        // await dispatch(closeDialog());
        const error = err.response.data.serverMsg;
        if (error) {
            await dispatch(setAlert(error, 'error'));
        }
        await dispatch({
            type: types.DELETE_LOCKER_FAIL
        });
    });
};

//! Fetch locker
export const fetchLocker = (lockerId) => async (dispatch) => {
    await dispatch({ type: types.FETCH_LOCKER_PENDING });
    await dispatch(fetchLockerReq(lockerId));
};

export const fetchLockerReq = (lockerId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.get(`${PROD_URL}/api/locker/fetch_locker/${lockerId}`, config)
    .then(async (res) => {
        await dispatch({
            type: types.FETCH_LOCKER_SUCCESS,
            payload: res.data.locker
        });
    }).catch(async (err) => {
        const error = err.response.data.serverMsg;
        if (error) {
            await dispatch(setAlert(error, 'error'));
        }
        await dispatch({
            type: types.FETCH_LOCKER_FAIL
        });
    });
};