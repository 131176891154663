import React from 'react';

// utils
import Constants from '../../../utils/Constants';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Iconify from '../../Layout/Iconify/Iconify';
import Tooltip from '../../Layout/Tooltip/Tooltip';

const BulletItem = (props) => {
    const { bullet, onDelete, onEdit } = props;

    return (
        <div className="small-shadow large-rad" style={styles.root}>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <h5 style={styles.bulletTitle} className="light-text">{bullet.title}</h5>

                <div style={styles.actionWrap}>
                    <Tooltip text="Delete">
                        <span onClick={() => {
                            onDelete(bullet._id);
                        }}>
                            <Iconify style={styles.bullIcon} iconName="delete" />
                        </span>
                    </Tooltip>
                    <Tooltip style={{ marginLeft: '1rem' }} text="Edit">
                        <span onClick={() => { onEdit(bullet._id) }}>
                            <Iconify style={styles.bullIcon} iconName="edit" />
                        </span>
                    </Tooltip>
                </div>
            </Wrapper>
        </div>
    );
};

const styles = {
    root: {
        backgroundColor: '#141414',
        width: '100%',
        padding: '.5rem .2rem',
        margin: '1rem 0'
    },
    bulletTitle: {
        fontWeight: 700,
        fontSize: '1.2rem',
        margin: 0,
        padding: 0
    },
    // button wrapper that contains the actions
    actionWrap: {
        display: 'flex',
        alignItems: 'center',

    },
    // icon style
    bullIcon: {
        color: Constants.secondary,
        // marginLeft: '.5rem',
        cursor: 'pointer'
    }
};

export default BulletItem;