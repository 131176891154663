import React, { useState } from 'react';
import { Spin as Hamburger } from 'hamburger-react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    logout
} from '../../../store/actions/auth';
import { isEmpty } from 'jvh-is-empty';
import {
    openDialog
} from '../../../store/actions/dialog';

//utils 
import './mobileNav.css';
import Constants from '../../../utils/Constants';

// components
import Button from '../Button/Button';
import TerminateAccount from '../../TerminateAccount/TerminateAccount'; 
import CreateLocker from '../../Lockers/CreateLocker/CreateLocker';

const MobileNav = ({ authLoading, isAuth, user, history, location }) => {
    const [isOpen, setOpen] = useState(false)
    const dispatch = useDispatch();

    const styles = {
        sideNav: {
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, .4)',
            WebkitBackdropFilter: 'blur(6px)',
            MozBackdropFilter: 'blur(6px)',
            MsBackdropFilter: 'blur(6px)',
            backdropFilter: 'blur(6px)',
            width: '100%',
            maxWidth: '14rem',
            height: `${window.innerHeight}px`,
            zIndex: 999,
            WebkitAnimationDuration: '.4s',
            WebkitAnimationIterationCount: 1,
            WebkitAnimationFillMode: 'both',
            WebkitAnimationName: 'mobile-nav-out',
            MozAnimationDuration: '.4s',
            MozAnimationIterationCount: 1,
            MozAnimationFillMode: 'both',
            MozAnimationName: 'mobile-nav-out',
            MsAnimationDuration: '.4s',
            MsAnimationIterationCount: 1,
            MsAnimationFillMode: 'both',
            MsAnimationName: 'mobile-nav-out',
            animationDuration: '.4s',
            animationIterationCount: 1,
            animationFillMode: 'both',
            animationName: 'mobile-nav-out',
            boxShadow: '9px 4px 21px 5px rgba(0,0,0,0.26)'
        },
        navLinks: {
            textDecoration: 'none',
            display: 'block',
            width: '100%',
            cursor: 'pointer',
            padding: '.8rem .2rem',
            fontSize: '1.2rem'
        },
        backdrop: {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            zIndex: 998,
            WebkitTransition: 'all 0.3s',
            MozTransition: 'all 0.3s',
            OTransition: 'all 0.3s',
            transition: 'all 0.3s',
        }
    };
    
    const onLinkClick = () => {
        setOpen(false);
    };

    const createLockerForm = (closeDialog) => <CreateLocker closeDialog={closeDialog} />;
    const terminateAccount = (closeDialog) => <TerminateAccount closeDialog={closeDialog} />;

    const renderDynamicLinks = () => {
        switch (location) {
            case '/locker':
                return <Button onClick={() => {
                    dispatch(openDialog('Create Locker', createLockerForm, 30));
                    onLinkClick();
                }} type="button">New Locker</Button>
            case '/create_padlock':
                return <Button onClick={() => {
                    dispatch(openDialog('Create Locker', createLockerForm, 30));
                    onLinkClick();
                }} type="button">New Locker</Button>
            case '/account':
                return <Button onClick={() => {
                    dispatch(openDialog('Terminate Account', terminateAccount, 30));
                    onLinkClick();
                }} 
                type="button">Terminate</Button>
            case '/reports':
                return <Button onClick={() => { history.push('/backlogs'); onLinkClick(); }}>Backlogs</Button>
            default: return;
        }
    };

    const renderAdminLinks = () => {
        if (authLoading) {
            return null;
        } else if (!authLoading && !isAuth) {
            return null;
        } else if (!authLoading && !isEmpty(user) && !user.isAdmin) {
            return null;
        } else if (!authLoading && isAuth && !isEmpty(user) && user.isAdmin) {
            return (
                <Link
                    to="/new_backlog"
                    className="light-text"
                    style={styles.navLinks}
                    onClick={onLinkClick}
                >Backlog</Link>
            );
        }
    };

    const renderLinks = (
        <>
            <a
                href="#!"
                style={styles.navLinks}
                onClick={() => {
                    dispatch(logout(history));
                    onLinkClick();
                }}
                className="light-text"
            >
                Logout
            </a>
            <Link
                to="/locker"
                className="light-text"
                style={styles.navLinks}
                onClick={onLinkClick}
            >Locker</Link>
            <Link
                to="/account"
                className="light-text"
                style={styles.navLinks}
                onClick={onLinkClick}
            >Account</Link>
            <Link
                to="/create_padlock"
                className="light-text"
                style={styles.navLinks}
                onClick={onLinkClick}
            >New Padlock</Link>
            <Link
                to="/reports"
                className="light-text"
                style={styles.navLinks}
                onClick={onLinkClick}
            >Reports</Link>
            {renderAdminLinks()}
            {renderDynamicLinks()}
        </>
    );

    return (
        <>
            <Hamburger
                toggled={isOpen}
                color={Constants.secondary}
                toggle={setOpen}
            />
            {isOpen && (
                <div style={styles.sideNav}>
                    {renderLinks}

                </div>
            )}
        </>
    );
};



export default MobileNav;