import securityQuestions from "./securityQuestions";
import passwordReq from "./passwordReq";
import reportTypes from "./reportTypes";
import backlogPriorityLevel from "./backlogPriorityLevel";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    bodyBg: '#141414',
    main: '#350062',
    secondary: '#00D8FF',
    BasicSpinner: '#500192',
    PageSpinner: '#47ff2f',
    alertBg: '#001C47',
    alertText: 'white',
    btnBg: '#424242',
    btnColor: 'white',
    navBg: '#0303038c',
    cardBg: '#131313',
    dialogBg: '#131313',
    lightGrey: '#424242',
    BASE_URL: 'http://localhost:8080',
    PROD_URL: 'https://api-dot-covertlocker.uc.r.appspot.com/',
    tooltipBg: 'rgba(19, 19, 19, .8)',
    securityQuestions,
    passwordReq,
    reportTypes,
    backlogPriorityLevel
};