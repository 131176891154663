import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../store/actions/dialog';
import { withRouter, useParams } from 'react-router-dom';
import {
    fetchPadlock
} from '../../store/actions/padlock';

// utils
import Constants from '../../utils/Constants';
import { decrypt } from '../../utils/crypto';

// components
import Password from '../../components/Verification/Password/Password';
import PadlockData from '../../components/Padlock/PadlockData/PadlockData';
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import Iconify from '../../components/Layout/Iconify/Iconify';

const Padlock = (props) => {
    const dispatch = useDispatch();
    const { history } = props;
    const [hasVerified, setHasVerified] = useState(false);
    const { authLoading, isAuth } = useSelector((state) => state.auth);
    const { fetching, padlock } = useSelector((state) => state.padlock);
    const { padlockId } = useParams();

    const verifyPassForm = (closeDialog) => <Password setHasVerified={setHasVerified} history={history} closeDialog={closeDialog} />;

    // setup the password first
    useEffect(() => {
        dispatch(openDialog('Verification', verifyPassForm, 30));
    }, []);

    useEffect(() => {
        if (hasVerified && padlockId) {
            dispatch(fetchPadlock(decrypt(padlockId)));
        }
    }, [hasVerified, padlockId]);

    const renderPadlockData = () => {
        if (!hasVerified) {
            return null;
        }
        else if (hasVerified && !fetching && !isEmpty(padlock)) {
            return <PadlockData
                padlock={padlock}
            />
        }
    };

    const renderBackBar = () => {
        return (
            <section className="large-shadow large-rad" style={styles.backBar}>
                <span onClick={() => props.history.goBack()} className="light-text" style={styles.backBtn}>
                    <Iconify iconName="leftArrow" style={styles.backIcon} />
                    Go Back
                </span>
            </section>
        );
    };

    return fetching && isEmpty(padlock) ?
        <span style={styles.pageLoader}>
            <PageLoader />
        </span>
        : (
            <article style={styles.root}>
                {hasVerified && !fetching && !isEmpty(padlock) &&
                    <>
                        <Wrapper>
                            {renderBackBar()}
                        </Wrapper>
                        <Wrapper>
                            {renderPadlockData()}
                        </Wrapper>
                    </>
                }
            </article>
        );
};

const styles = {
    root: {
        margin: '3rem 0 3rem 0'
    },
    pageLoader: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MozTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    },
    // bar above the padlock to see what directory you're in as well as go back 
    backBar: {
        backgroundColor: Constants.main,
        width: '90%',
        maxWidth: '60rem',
        padding: '1rem',
        margin: '1rem 0 3rem 0'
    },
    // back button
    backBtn: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    backIcon: {
        color: Constants.secondary,
        marginRight: '.3rem'
    }
};

export default withRouter(Padlock);