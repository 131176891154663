import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createLocker
} from '../../../store/actions/locker';

// utils

// components
import Input from '../../Layout/Input/Input';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';

const CreateLocker = (props) => {
    const { closeDialog } = props;
    const dispatch = useDispatch();
    const [lockerName, setLockerName] = useState('');
    const { creating } = useSelector((state) => state.locker);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!lockerName) {
            return;
        }

        await dispatch(createLocker(lockerName));
    };

    return (
        <form onSubmit={(e) => onSubmit(e)} style={styles.form}>
            <div className="form-block">
                <label className="input-label light-text">Locker Name</label>
                <Input
                    name="lockerName"
                    value={lockerName}
                    onChange={(e) => setLockerName(e.target.value)}
                    inputType="input"
                    placeholder="Locker Name"
                />
            </div>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <Button type="button" onClick={() => dispatch(closeDialog())}>Close</Button>
                <Button type="submit" loading={creating}>Create</Button>
            </Wrapper>
        </form>
    );
};

const styles = {
    form: {

    }
};

export default CreateLocker;