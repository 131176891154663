import React, { useState } from 'react';
import {
    login,
    registerUser
} from '../../store/actions/auth';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setAlert } from '../../store/actions/alert';

// components
import Login from '../../components/Login/Login';
import Register from '../../components/Register/Register';
import Wrapper from '../../components/Layout/Wrapper/Wrapper';

// utils
import Constants from '../../utils/Constants';

const Auth = (props) => {
    const [isLogin, setIsLogin] = useState(true);
    const { authLoading, isAuth } = useSelector((state) => state.auth);

    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    const styles = {
        root: {
            position: isLogin ? 'fixed' : 'relative',
            top: isLogin && '50%',
            left: isLogin && '50%',
            WebkitTransform: isLogin && 'translate(-50%, -50%)',
            MozTransform: isLogin && 'translate(-50%, -50%)',
            OTransform: isLogin && 'translate(-50%, -50%)',
            transform: isLogin && 'translate(-50%, -50%)',
            padding: '1.5rem',
            width: '90%',
            maxWidth: isLogin ? '30rem' : '50rem',
            backgroundColor: Constants.main,
        },
        authCard: {
            // backgroundColor: Constants.main,
            // minHeight: '20rem',
            // width: '100%',
            // maxWidth: '20rem',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center'
        },
        formTitle: {
            fontSize: '2rem',
            margin: 0,
            padding: 0
        },
        formChangeText: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        },
        toggleFormText: {
            color: Constants.secondary,
            cursor: 'pointer',
            marginLeft: '.3rem'
        },
        // forgot password text button
        forgetPassText: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            color: Constants.secondary,
            cursor: 'pointer'
        }
    };

    const renderAuthJsx = () => {
        return (
            <article className="large-rad large-shadow" style={styles.root}>
                <section style={styles.authCard}>
                    {isLogin ? <h3 className="light-text" style={styles.formTitle}>Login</h3> : <h3 className="light-text" style={styles.formTitle}>Register</h3>}
                    {
                        isLogin ? <Login
                            login={login}
                            history={props.history}
                            authLoading={authLoading}
                            setAlert={setAlert}
                        />
                            :
                            <Register
                                history={props.history}
                                authLoading={authLoading}
                                register={registerUser}
                                setAlert={setAlert}
                            />
                    }
                    {isLogin ?
                        <p className="light-text" style={styles.formChangeText}>Don't have an account? <span onClick={toggleForm} style={styles.toggleFormText}>Register</span></p>
                        :
                        <p className="light-text" style={styles.formChangeText}>Already have an account? <span onClick={toggleForm} style={styles.toggleFormText}>Login</span></p>
                    }
                    {isLogin && <span style={styles.forgetPassText}>Forgot Password?</span>}
                </section>
            </article>
        )
    };

    return isLogin ? (
        renderAuthJsx()
    ) : (
        <Wrapper styles={{ margin: '3rem 0'}}>
            {renderAuthJsx()}
        </Wrapper>
    )
};

export default withRouter(Auth);