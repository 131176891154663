import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';
import { withRouter } from 'react-router-dom';
import {
    inputPadlockFilter,
    clearPadlockFilter
} from '../../../store/actions/padlockFilter';
import {
    openDialog
} from '../../../store/actions/dialog';

// utils
import Constants from '../../../utils/Constants';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Input from '../../Layout/Input/Input';
import Iconify from '../../Layout/Iconify/Iconify';
import Tooltip from '../../Layout/Tooltip/Tooltip';
import RoundButton from '../../Layout/RoundButton/RoundButton';
import MoreMenu from '../MoreMenu/MoreMenu';

const PadlockFilterBar = (props) => {
    const { history, lockerId } = props;
    const [folderName, setFolderName] = useState('');
    const [inputSearch, setInputSearch] = useState('');
    const [copyPadlocks, setCopyPadlocks] = useState([]);
    const dispatch = useDispatch();
    const { fetching, padlocks } = useSelector((state) => state.padlock);

    // handle assigning the folder name to what is being fetched
    useEffect(() => {
        if (!isEmpty(padlocks)) {
            // create an original copy of the padlocks
            let copyArr = padlocks.map((pad) => pad);
            setCopyPadlocks(copyArr);

            let folderNames = [];
            for (let pad of padlocks) {
                if (!JSON.stringify(folderNames).includes(pad.locker)) {
                    folderNames.push(pad.locker);
                } else {
                    continue
                }
            }
            if (folderNames.length > 1) {
                return;
            } else if (folderNames.length === 1) {
                setFolderName(folderNames[0]);
            }
        }
    }, [fetching]);

    // on change event for the input change to update padlock state
    useEffect(() => {
        if (!isEmpty(inputSearch)) {
            let validatedArr = [];

            copyPadlocks.map((pad) => {
                if (!JSON.stringify(pad.title.toLowerCase()).includes(inputSearch.toLowerCase())) {
                    return;
                }
                validatedArr.push(pad);
            });
            if (isEmpty(validatedArr)) {
                dispatch(inputPadlockFilter([]));
                return;
            }
            dispatch(inputPadlockFilter([...validatedArr]));
        } else if (isEmpty(inputSearch)) {
            dispatch(inputPadlockFilter([...copyPadlocks]));
        }
    }, [inputSearch]);

    const onClickBack = () => {
        history.goBack();
    };

    const onInputChange = (value) => {
        setInputSearch((prevState) => {
            // setting this so that when the user back spaces input to an empty value, we 
            // recall the original padlocks
            if (!isEmpty(prevState) && isEmpty(value)) {
                console.log('making call in prevstate')
                // dispatch(inputPadlockFilter([...copyPadlocks]));
            }
            return value;
        });
    };

    const onFilterClear = () => {
        setInputSearch('');
        dispatch(clearPadlockFilter([...copyPadlocks]));
    };

    const renderMoreForm = (closeDialog) => <MoreMenu lockerId={lockerId} padlocks={padlocks} closeDialog={closeDialog} />

    return (
        <section className="large-rad large-shadow" style={styles.root}>
            {/* <Wrapper styles={styles.folderNameWrapper}>
                <main style={styles.folderNameFrame} className="">
                    <h3 style={styles.folderName} className="light-text">{folderName}</h3>
                </main>
            </Wrapper> */}
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <div style={styles.leftCol}>
                    <span className="light-text" onClick={onClickBack} style={styles.goBackWrap}>
                        <Iconify iconName="leftArrow" style={styles.backIcon} />
                        Go Back
                    </span>
                </div>
                <div style={styles.rightCol}>
                    <Input
                        inputType="input"
                        type="text"
                        value={inputSearch}
                        onChange={(e) => onInputChange(e.target.value)}
                        name="inputSearch"
                        placeholder="Search..."
                    />
                    <Tooltip onClick={onFilterClear} style={{ marginLeft: '1rem' }} text="Clear Filters">
                        <RoundButton style={styles.roundClearFormBtn}>
                            <Iconify style={styles.clearFilterIcon} iconName="close" />
                        </RoundButton>
                    </Tooltip>
                    <Tooltip text="More" onClick={() => dispatch(openDialog('More Actions', renderMoreForm, 35))}>
                        <Iconify style={styles.moreVertIcon} iconName="moreVert" />
                    </Tooltip>
                </div>
            </Wrapper>
        </section>
    );
};

const styles = {
    root: {
        width: '90%',
        maxWidth: '60rem',
        backgroundColor: Constants.main,
        padding: '1rem',
        margin: '3rem 0 0 0'
    },
    // wrapper around all folder name data and frames
    folderNameWrapper: {
        marginTop: '-3rem'
    },
    // frame for the folder name
    folderNameFrame: {
        backgroundColor: Constants.bodyBg,
        width: '100%',
        maxWidth: '30rem',
        padding: '1rem',
    },
    folderName: {
        padding: 0,
        margin: 0,
        textAlign: 'center'
    },
    // right container column
    rightCol: {
        display: 'flex',
        alignItems: 'center',
        width: '28rem'
    },
    // left container column
    leftCol: {
        display: 'flex',
        alignItems: 'center',
        width: '22rem'
    },
    // wrapper for going back to lockers
    goBackWrap: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    // back icon
    backIcon: {
        marginRight: '.3rem',
        color: Constants.secondary
    },
    roundClearFormBtn: {
        backgroundColor: Constants.lightGrey,
        height: '2rem',
        width: '2rem',
        padding: '.2rem'
    },
    // clear filter icon button
    clearFilterIcon: {
        color: Constants.secondary,
        fontSize: '2rem'
    },
    // vertical more icon
    moreVertIcon: {
        fontSize: '2.5rem',
        color: Constants.secondary,
        padding: 0,
        // marginLeft: '1rem',
        cursor: 'pointer'
    }
};

export default withRouter(PadlockFilterBar);