import * as types from '../actions/types/backlog';

const initialState = {
    creating: false,
    fetching: false,
    backlog: null,
    backlogs: []
};

const backlogReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.CREATE_BACKLOG_FAIL:
            return {
                ...state,
                creating: false
            };
        case types.CREATE_BACKLOG_PENDING:
            return {
                ...state,
                creating: true
            };
        case types.CREATE_BACKLOG_SUCCESS:
            return {
                ...state,
                creating: false,
                backlogs: [payload, ...state.backlogs],
                backlog: payload
            };
        case types.FETCH_BACKLOGS_FAIL:
        case types.FETCH_BACKLOG_FAIL:
            return {
                ...state,
                fetching: false
            };
        case types.FETCH_BACKLOGS_PENDING:
        case types.FETCH_BACKLOG_PENDING:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_BACKLOGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                backlogs: [...payload]
            };
        case types.FETCH_BACKLOG_SUCCESS:
            return {
                ...state,
                fetching: false,
                backlog: payload
            };
        default:
            return state;
    }
};
export default backlogReducer;