export default [
    {
        name: 'What is the name of your first pet?',
        value: 'What is the name of your first pet?'
    },
    {
        name: 'Where did you graduate highschool?',
        value: 'Where did you graduate highschool?'
    },
    {
        name: 'Where did you meet your spouse?',
        value: 'Where did you meet your spouse?'
    },
    {
        name: 'What is your mothers maiden name?',
        value: 'What is your mothers maiden name?'
    },
    {
        name: 'What is your favorite book?',
        value: 'What is your favorite book?'
    },
    {
        name: 'What was the first company you worked for?',
        value: 'What was the first company you worked for?'
    },
    {
        name: 'What is your favorite food?',
        value: 'What is your favorite food?'
    },
    {
        name: 'What is your favorite state?',
        value: 'What is your favorite state?'
    },
];
