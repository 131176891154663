export default [
    {
        label: '1 Lowercase'
    },
    {
        label: '1 Uppercase'
    },
    {
        label: '1 Number'
    },
    {
        label: '1 Special Character'
    },
    {
        label: 'Contains 6 Characters'
    },
    {
        label: 'Passwords Match'
    }
];