export default [
    {
        name: 'Enhancement',
        value: 'Enhancement'
    },
    {
        name: 'Bug',
        value: 'Bug'
    },
    {
        name: 'Question',
        value: 'Question'
    },
    {
        name: 'Say Hi',
        value: 'Say Hi'
    }
];