import * as types from './types/confirmModal';

export const openConfirmModal = (modalText, modalAction) => async (dispatch) => {
    await dispatch({
        type: types.OPEN_CONFIRM_MODAL,
        payload: {
            modalText,
            modalAction
        }
    });
};

export const closeConfirmModal = () => async (dispatch) => {
    await dispatch({ type: types.CLOSE_CONFIRM_MODAL });
};