import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateLocker,
    deleteLocker
} from '../../../store/actions/locker';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';
import Iconify from '../../Layout/Iconify/Iconify';
import Input from '../../Layout/Input/Input';
import { isEmpty } from 'jvh-is-empty';

const EditLocker = (props) => {
    const { lockerName, lockerId, closeDialog } = props;
    const [formData, setFormData] = useState({
        lockerName: '',
        oldLockerName: ''
    });
    const dispatch = useDispatch();
    const { editing, deleting } = useSelector((state) => state.locker);

    useEffect(() => {
        setFormData({
            lockerName: !isEmpty(lockerName) ? lockerName : '',
            oldLockerName: !isEmpty(lockerName) ? lockerName : ''
        });
    }, [lockerName]);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.oldLockerName === formData.lockerName) {
            await dispatch(closeDialog())
            return;
        }

        await dispatch(updateLocker(formData.lockerName, lockerId));
    };

    return (
        <main>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="form-block">
                    <label className="input-label light-text">Change Locker Name</label>
                    <Input
                        inputType="input"
                        value={formData.lockerName}
                        placeholder="Locker Name"
                        name="lockerName"
                        onChange={(e) => setFormData({ ...formData, lockerName: e.target.value })}
                    />
                </div>
                <Wrapper styles={{ justifyContent: 'space-between' }}>
                    <Button onClick={() => dispatch(closeDialog())} type="button">Close</Button>
                    <Button loading={editing || deleting} type="submit">Save</Button>
                </Wrapper>
            </form>
        </main>
    );
};

const styles = {

};

export default EditLocker;