import React from 'react';

// utils
import Constants from '../../../utils/Constants';

// components
import MiniLoader from '../MiniLoader/MiniLoader';

const RoundButton = (props) => {
    const { disabled, type, onClick, style } = props;

    const styles = {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: Constants.lightGrey,
        color: Constants.secondary,
        cursor: 'pointer',
        border: '1px solid transparent',
        fontSize: '1.6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    return <button className="small-shadow" style={{ ...styles, ...style }} type={type} onClick={onClick}>{!props.loading ? props.children : <MiniLoader />}</button>
};

export default RoundButton;