import React, { useState, useEffect } from 'react';

// utils
import Constants from '../../../utils/Constants';

const Check = (props) => (
    <svg style={{ marginTop: '.08rem'}} viewBox="0 0 16 16">
        <path style={props.style} d="M13 .156l-1.406 1.438-5.594 5.594-1.594-1.594-1.406-1.438-2.844 2.844 1.438 1.406 3 3 1.406 1.438 1.406-1.438 7-7 1.438-1.406-2.844-2.844z" transform="translate(0 1)" />
    </svg>
);

const Checkbox = (props) => {
    const { checkboxCheckedHandler, disabled, type, checked, style, index } = props;

    const dictateCheckboxCheckedHandler = (e) => {
        switch (type) {
            default: return;
        }
    };

    const styles = {
        checkbox: {
            content: "",
            verticalAlign: 'top',
            display: 'inline-block',
            width: '1.3rem',
            height: '1.3rem',
            padding: '2px',
            border: '1px solid #ddd',
            borderRadius: '50%',
            margin: '0 12px 0 0',
            color: '#fff',
            fontSize: '.7rem',
            cursor: !disabled ? 'pointer' : 'default'
        },
        checkIcon: {
            color: 'black',
            
        }
    };

    return (
        <span
            onClick={(e) => {
                if (!disabled) {
                    dictateCheckboxCheckedHandler(e);
                }
            }}
            style={{
                ...styles.checkbox,
                ...style,
                borderColor: checked ? Constants.secondary : 'white',
                backgroundColor: checked ? Constants.secondary : ''
            }}
        >
            {checked ? <Check style={styles.checkIcon} /> : null}
        </span>
    );
};


export default Checkbox;