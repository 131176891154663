import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/actions/alert';
import { openDialog } from '../../../store/actions/dialog';
import { withRouter } from 'react-router-dom';
import { openConfirmModal } from '../../../store/actions/confirmModal';
import {
    deletePadlock
} from '../../../store/actions/padlock';

// utils
import { decrypt, encrypt } from '../../../utils/crypto';
import Constants from '../../../utils/Constants';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Iconify from '../../Layout/Iconify/Iconify';
import Tooltip from '../../Layout/Tooltip/Tooltip';
import Moment from 'react-moment';
import RoundButton from '../../Layout/RoundButton/RoundButton';
import LockerChangeForm from '../LockerChangeForm/LockerChangeForm';

const PadlockItem = (props) => {
    const { padlock, history } = props;
    // dictates whether to show th cards encrypted data
    const [isEncrypted, setIsEncrypted] = useState(true);
    const dispatch = useDispatch();
    const { deleting, updating } = useSelector((state) => state.padlock);
    const [renderMobileView, setRenderMobileView] = useState(false);

    useEffect(() => {
        const handleRules = () => {
            if (window.innerWidth <= 499) {
                setRenderMobileView(true);
            } else {
                setRenderMobileView(false);
            }
        };
        window.addEventListener('resize', handleRules)
        return () => window.removeEventListener('resize', handleRules);
    }, []);

    useEffect(() => {
        if (!isEncrypted) {
            decrypt(padlock.username);
        }
    }, [isEncrypted]);

    const copyHandler = (value) => {
        navigator.clipboard.writeText(decrypt(value));
        dispatch(setAlert('Copied to your clipboard', 'success'));
    };
    const renderChangeLockerForm = (closeDialog) => <LockerChangeForm
        padlockId={padlock._id}
        lockerId={padlock.lockerId}
        locker={padlock.locker}
        closeDialog={closeDialog}
    />
    const styles = {
        card: {
            width: '100%',
            maxWidth: '35rem',
            backgroundColor: Constants.main,
            margin: '6rem 0 0 0',
            padding: '1rem 1rem'
        },
        // header of the card
        header: {
            backgroundColor: Constants.bodyBg,
            width: '100%',
            maxWidth: '27rem',
            padding: '.5rem .5rem',
            marginTop: '-2rem'
        },
        cardTitle: {
            padding: 0,
            margin: 0,
            fontSize: '1.6rem'
        },
        // created at date text
        createdDate: {
            fontSize: '.8rem',
            fontWeight: 500,
            color: 'white'
        },
        viewLockedIcon: {
            cursor: 'pointer',
            fontSize: '1.4rem',
            // color: Constants.secondary
        },
        cardBody: {
            margin: '1.5rem 0'
        },
        // wrapper around the frame and copy button
        inputContainer: {
            marginBottom: '1.5rem',
            marginTop: '.5rem'
        },
        // creates a frame for the text to look like it's disabled
        inputFrame: {
            width: renderMobileView ? '70%' : '90%',
            backgroundColor: 'rgba(0,0,0,.3)',
            height: '2rem',
            border: '1px solid',
            borderColor: 'rgba(0,0,0,.3)',
            borderBottomLeftRadius: '3px',
            borderTopLeftRadius: '3px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'flex-start',
            padding: '0 .5rem',
        },
        // text inside the frame after its decrypted
        frameText: {
            fontSize: '1rem'
        },
        // label for the frame
        frameLabel: {
            fontWeight: 600,
            fontSize: '1.2rem'
        },
        copyBtn: {
            width: renderMobileView ? '30%' : '10%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '2rem',
            border: '1px solid white',
            borderBottomRightRadius: '3px',
            borderTopRightRadius: '3px',
            cursor: 'pointer',
            fontSize: '.9rem',
            fontWeight: 600
        },
        // icons that are rendered when text is hidden
        bulletIcons: {
            color: Constants.secondary,
            fontSize: '.8rem',
            margin: '0 .5rem',
        },
        footerBtns: {
            // margin: '.5rem 1rem'
        }
    };

    const onConfirmDelete = async () => {
        await dispatch(deletePadlock(padlock._id, () => {}));
    };
    
    return (
        <main className="large-shadow large-rad" style={styles.card}>
            <Wrapper>
                <div style={styles.header} className="large-shadow small-rad">
                    <Wrapper styles={{ justifyContent: 'space-between' }}>
                        <h3 className="light-text" style={styles.cardTitle}>{padlock.title}</h3>
                        <span onClick={() => setIsEncrypted(!isEncrypted)}>
                            <Tooltip text="Toggle View">
                                <RoundButton>
                                    <Iconify style={styles.viewLockedIcon} iconName={isEncrypted ? 'lock' : 'unlock'} />
                                </RoundButton>
                            </Tooltip>
                        </span>
                    </Wrapper>
                    <Wrapper>
                        <Tooltip text="Created Date">
                            <Moment style={styles.createdDate} format="ll">{padlock.createdAt}</Moment>
                        </Tooltip>
                    </Wrapper>
                </div>
            </Wrapper>
            <div style={styles.cardBody}>
                <span className="light-text" style={styles.frameLabel}>Username</span>
                <Wrapper styles={styles.inputContainer}>
                    <div style={styles.inputFrame}>
                        <p className="light-text" style={{ margin: 0, padding: 0, ...styles.frameText }}>
                            {
                                isEncrypted ?
                                    [...Array(renderMobileView ? 3 : 7)].map((_, i) => <Iconify key={i} style={styles.bulletIcons} iconName="lock" />)
                                    : decrypt(padlock.username)
                            }
                        </p>
                    </div>
                    <button style={styles.copyBtn} onClick={() => {
                        copyHandler(padlock.username)
                    }}>Copy</button>
                </Wrapper>
                <span className="light-text" style={styles.frameLabel}>Password</span>
                <Wrapper styles={styles.inputContainer}>
                    <div style={styles.inputFrame}>
                        <p className="light-text" style={{ margin: 0, padding: 0, ...styles.frameText }}>
                            {
                                isEncrypted ?
                                    [...Array(renderMobileView ? 3 : 7)].map((_, i) => <Iconify key={i} style={styles.bulletIcons} iconName="lock" />)
                                    : decrypt(padlock.password)
                            }
                        </p>
                    </div>
                    <button style={styles.copyBtn} onClick={() => {
                        copyHandler(padlock.password)
                    }}>Copy</button>
                </Wrapper>
            </div>
            <div style={styles.cardFooter}>
                <Wrapper styles={{ justifyContent: 'space-evenly' }}>
                    <Tooltip
                        onClick={() => {
                            dispatch(openConfirmModal('Are you sure you want to delete this padlock?', onConfirmDelete));
                        }}
                        style={styles.footerBtns}
                        text="Delete Padlock"
                    >
                        <RoundButton loading={deleting} type="button">
                            <Iconify iconName="delete" />
                        </RoundButton>
                    </Tooltip>
                    <Tooltip
                        onClick={() => {
                            dispatch(openDialog('Change Locker', renderChangeLockerForm, 30))
                        }}
                        style={styles.footerBtns}
                        text="Move Location"
                    >
                        <RoundButton loading={updating} type="button">
                            <Iconify iconName="move" />
                        </RoundButton>
                    </Tooltip>
                    <Tooltip
                        onClick={() => history.push(`/padlock/${encrypt(padlock._id)}`)}
                        style={styles.footerBtns}
                        text="View More"
                    >
                        <RoundButton type="button" onClick={() => { }}>
                            <Iconify iconName="more" />
                        </RoundButton>
                    </Tooltip>
                </Wrapper>
            </div>
        </main>
    );
};


export default withRouter(PadlockItem);