import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useSelector, useDispatch } from 'react-redux';
import {
    updatePadlock
} from '../../../store/actions/padlock';
import {
    fetchLockers
} from '../../../store/actions/locker';

// utils
import formatDropdownOptions from '../../../utils/formatDropdownOptions';

// components
import CustomDropdown from '../../Layout/CustomDropdown/CustomDropdown';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';
import MiniLoader from '../../Layout/MiniLoader/MiniLoader';

const LockerChangeForm = (props) => {
    const { padlockId, closeDialog, lockerId, locker } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        locker: !isEmpty(locker) ? locker : '',
        lockerId: !isEmpty(lockerId) ? lockerId : ''
    });
    const { fetching, lockers } = useSelector((state) => state.locker);
    const { updating } = useSelector((state) => state.padlock);

    // useEffect for fetching the lockers if there are none in the store state
    useEffect(() => {
        if (!fetching && isEmpty(lockers)) {
            dispatch(fetchLockers());
        }
    }, []);

    const onDropdownChange = (data) => {
        setFormData({
            ...formData,
            locker: data.name,
            lockerId: data.value
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (lockerId === padlockId) {
            await dispatch(closeDialog());
        }
        await dispatch(updatePadlock(formData, padlockId, (x) => { dispatch(closeDialog()) }));
    };

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-block">
                <label className="light-text input-label">Select a Locker</label>
                {fetching && <MiniLoader />}
                {!fetching && !isEmpty(lockers) && <CustomDropdown
                    handleChange={onDropdownChange}
                    isPredefined={true}
                    predefinedValue={formData.locker}
                    type="lockers"
                    isSearchable={true}
                    options={formatDropdownOptions(lockers, 'lockers')}
                    placeholder="Select a locker"
                />}
            </div>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <Button type="button" onClick={() => dispatch(closeDialog())}>Cancel</Button>
                <Button type="submit" loading={updating}>Save</Button>
            </Wrapper>  
        </form>
    );
};

export default LockerChangeForm