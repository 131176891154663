import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    fetchBacklog
} from '../../store/actions/backlog';
import { isEmpty } from 'jvh-is-empty';

// utils
import { decrypt } from '../../utils/crypto';
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import Markdown from '../../components/Markdown/Markdown';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';

const Backlog = (props) => {
    const { backlogId } = useParams();
    const dispatch = useDispatch();
    const { fetching, backlog } = useSelector((state) => state.backlog);

    // upon component load... fetch the backlog with the given id
    useEffect(() => {
        if (!isEmpty(backlogId)) {
            dispatch(fetchBacklog(decrypt(backlogId)));
        }
    }, []);

    // function that returns the color and names of the priority level
    const renderPriorityLevel = (type) => {
        if (fetching || isEmpty(backlog)) {
            return null;
        }
        if (!fetching && !isEmpty(backlog)) {
            let data = {
                word: '',
                color: ''
            };
            switch (backlog.priority) {
                case 1:
                    data.word = 'Low';
                    data.color = '#64FF00';
                    return data[type]
                case 2:
                    data.word = 'Medium';
                    data.color = '#FFE400';
                    return data[type]
                case 3:
                    data.word = 'High';
                    data.color = '#FF0000';
                    return data[type]
                default: return data;
            }
        }
    };

    const renderBacklogData = () => {
        if (fetching || isEmpty(backlog)) {
            return null;
        }
        return (
            <article className="large-rad large-shadow" style={styles.root}>
                <Wrapper styles={{ justifyContent: 'space-between' }}>
                    <h3 className="light-text" style={styles.title}>{backlog.title}</h3>
                    <p style={styles.priority}>{renderPriorityLevel('word')} Priority</p>
                </Wrapper>
                {/* <section style={styles.markdownContainer}> */}
                    <Markdown desc={backlog.desc} />
                {/* </section> */}
            </article>
        );
    };

    const styles = {
        pageLoader: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            WebkitTransform: 'translate(-50%, -50%)',
            MozTransform: 'translate(-50%, -50%)',
            OTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)'
        },
        root: {
            backgroundColor: Constants.main,
            padding: '1rem',
            margin: '3rem 1rem 4rem 1rem',
            width: '90%',
            maxWidth: '60rem'
        },
        title: {
            fontSize: '2rem',
            padding: 0,
            margin: 0
        },
        priority: {
            color: `${renderPriorityLevel('color')}`,
            fontWeight: 700,
            fontSize: '.8rem',
            padding: 0,
            margin: 0
        },
        markdownContainer: {
            backgroundColor: 'white',
            padding: '0 .5rem'
        }
    };

    return fetching ?
        <div style={styles.pageLoader}>
            <PageLoader />
        </div> : (
            <Wrapper>
                {renderBacklogData()}
            </Wrapper>
        );
};

export default Backlog;