import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    checkSecurity
} from '../../../store/actions/auth';
import { isEmpty } from 'jvh-is-empty';
import { withRouter } from 'react-router-dom';

// utils
import { encrypt } from '../../../utils/crypto';
// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';
import Input from '../../Layout/Input/Input';

const AnswerSecurity = (props) => {
    const { closeDialog, history, lockerId } = props;
    const dispatch = useDispatch();
    const { authLoading, user, securityLoading } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState({
        question: '',
        answer: '',
        email: ''
    });

    useEffect(() => {
        if (!authLoading && !isEmpty(user)) {
            setFormData({
                ...formData,
                email: encrypt(user.email),
                question: user.security.question
            });
        }
    }, [authLoading]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!formData.answer) {
            return;
        }
        await dispatch(checkSecurity(formData, lockerId));
    };

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <p className="light-text" style={styles.subTitle}>Are you who you say you are?</p>
            <div className="form-block">
                <label className="input-label light-text">{formData.question}</label>
                <Input 
                    inputType="input"
                    type="text"
                    value={formData.answer}
                    onChange={(e) => setFormData({ ...formData, answer: e.target.value })}
                    placeholder="Your answer here..."
                />
            </div>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <Button type="button" onClick={() => {
                    history.goBack();
                    dispatch(closeDialog());
                }}>Cancel</Button>
                <Button type="submit" loading={securityLoading}>Secure</Button>
            </Wrapper>
        </form>
    );
};

const styles = {
    // sub title below main title
    subTitle: {
        textAlign: 'center',
        fontSize: '1.3rem',
        padding: 0,
        marginTop: 0,
        marginBottom: '2rem'
    }
};

export default withRouter(AnswerSecurity);