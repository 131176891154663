import React from 'react';
import { Switch, Route } from 'react-router-dom';

//components
import Alert from '../Layout/Alert/Alert';
import Navbar from '../Layout/Navbar/Navbar';
import Dialog from '../Layout/Dialog/Dialog';
import ConfirmModal from '../Layout/ConfirmModal/ConfirmModal';
import PrivateRoute from './PrivateRoute';
import Footer from '../Layout/Footer/Footer';

// pages
import Auth from '../../pages/Auth/Auth';
import Account from '../../pages/Account/Account';
import Locker from '../../pages/Locker/Locker';
import Padlocks from '../../pages/Padlocks/Padlocks';
import CreatePadlock from '../../pages/CreatePadlock/CreatePadlock';
import Padlock from '../../pages/Padlock/Padlock';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import ReportBug from '../../pages/ReportBug/ReportBug';
import Backlogs from '../../pages/Backlogs/Backlogs';
import CreateBacklog from '../../pages/CreateBacklog/CreateBacklog';
import Backlog from '../../pages/Backlog/Backlog';
import Terms from '../../pages/Terms/Terms';
import LostPage from '../../pages/LostPage/LostPage';

const Routing = (props) => {

    return (
        <main>
            <Alert />
            <Navbar />
            <ConfirmModal />
            <Dialog />
            <Switch>
                <Route exact path="/" component={Auth} />
                <PrivateRoute exact path="/account" component={Account} />
                <PrivateRoute path="/account/change_password/:userId" component={ChangePassword} />
                <PrivateRoute exact path="/locker" component={Locker} />
                <PrivateRoute path="/locker/:lockerId" component={Padlocks} />
                <PrivateRoute path="/create_padlock" component={CreatePadlock} />
                <PrivateRoute exact path="/padlock/:padlockId" component={Padlock} />
                <PrivateRoute exact path="/reports" component={ReportBug} />
                <PrivateRoute exact path="/backlogs" component={Backlogs} />
                <PrivateRoute path='/backlogs/:backlogId' component={Backlog} />
                <PrivateRoute exact path="/new_backlog" component={CreateBacklog} />
                <Route exact path="/terms&conditions" component={Terms} />
                <Route path='*' component={LostPage} />
            </Switch>
            <Footer />
        </main>
    );
};

export default Routing;