//! DATE TYPE
export const DATE_SUCCESS = 'DATE_SUCCESS';

//! NAME TYPES
export const NAME_SUCCESS = 'NAME_SUCCESS';

//! INPUT TYPES
export const INPUT_PADLOCK_SUCCESS = 'INPUT_PADLOCK_SUCCESS';

//! CLEAR FILTER
export const CLEAR_PADLOCK_FILTER = 'CLEAR_PADLOCK_FILTER';