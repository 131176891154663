import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// components
import Input from '../Layout/Input/Input';
import PasswordInput from '../Layout/PasswordInput/PasswordInput';
import Wrapper from '../Layout/Wrapper/Wrapper';
import Button from '../Layout/Button/Button';

// utils

const Login = (props) => {
    const { login, history, authLoading, setAlert } = props;
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const dispatch = useDispatch();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const { email, password } = formData;
    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(login(formData, history));
        resetForm();
    };

    const resetForm = () => {
        setFormData({
            email: '',
            password: ''
        });
    };

    return (
        <form style={styles.form} onSubmit={(e) => onSubmit(e)}>
            <div className="form-block">
                <label className="input-label light-text">Email</label>
                <Input inputType="input" name="email" value={email} onChange={(e) => onChange(e)} placeholder="email@covertlocker.monster" />
            </div>
            <div className="form-block">
                <label className="input-label light-text">Password</label>
                <PasswordInput viewable placeholder="Your Password" name="password" value={password} onChange={(e) => onChange(e)} />
            </div>
            <Wrapper styles={{ justifyContent: 'flex-end' }}>
                <Button loading={authLoading} type="submit">Login</Button>
            </Wrapper>
            <p></p>
        </form>
    );
};

const styles = {
    form: {

    },

};

export default Login;