import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    createBacklog
} from '../../store/actions/backlog';
import { withRouter } from 'react-router-dom';

// utils
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import Input from '../../components/Layout/Input/Input';
import Button from '../../components/Layout/Button/Button';
import CustomDropdown from '../../components/Layout/CustomDropdown/CustomDropdown';
import Markdown from '../../components/Markdown/Markdown';

const CreateBacklog = ({ history }) => {
    const [formData, setFormData] = useState({
        title: '',
        desc: '',
        priority: NaN
    });
    const dispatch = useDispatch();
    const { creating } = useSelector((state) => state.backlog);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onMarkdownChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, desc: value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(createBacklog(formData, history));
    };

    const { title, desc } = formData;

    const onPriorityChange = (option) => {
        setFormData({
            ...formData,
            priority: option.value
        });
    };

    return (
        <article style={styles.root}>
            <Wrapper styles={{ justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                <section className="large-shadow large-rad" style={styles.leftCol}>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className="form-block">
                            <label className="input-label light-text">Title</label>
                            <Input
                                inputType="input"
                                type="text"
                                value={title}
                                name="title"
                                onChange={(e) => onChange(e)}
                                placeholder="Backlog Title"
                            />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Title</label>
                            <Input
                                inputType="textarea"
                                rows="30"
                                name="desc"
                                value={desc}
                                onChange={(e) => onMarkdownChange(e)}
                                placeholder="Markdown backlog description"
                            />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Priority Level</label>
                            <CustomDropdown
                                handleChange={onPriorityChange}
                                options={Constants.backlogPriorityLevel}
                                placeholder="Select a Priority level"
                                type="backlogPriority"
                            />
                        </div>
                        <Wrapper styles={{ justifyContent: 'flex-end' }}>
                            <Button loading={creating} type="submit">Create Backlog</Button>
                        </Wrapper>
                    </form>
                </section>
                <section className="large-shadow large-rad" style={styles.rightCol}>
                    <Markdown desc={desc} />
                </section>
            </Wrapper>
        </article>
    );
};

const styles = {
    root: {
        marginTop: '2rem',
        marginBottom: '5rem'
    },
    // both column sides
    leftCol: {
        padding: '1rem',
        margin: '1rem',
        width: '100%',
        maxWidth: '40rem',
        backgroundColor: Constants.main
    },
    rightCol: {
        backgroundColor: 'white',
        padding: '0 1rem',
        margin: '1rem',
        width: '100%',
        maxWidth: '45rem',
        height: 'auto',
        minHeight: '50rem'
    }
};

export default withRouter(CreateBacklog);

// # This is code below :)
// ---------------------------

// ```
// function returnThis(prop) {
//   return prop;
// }

// const variable = 'this is a string!';

// console.log(returnThis(variable));
// ```

// ##Look, an Image!

// ![kitten](https://res.cloudinary.com/vinaypuppal/image/upload/v1511183636/my_avatar.png)

// This is on GitHub (https://github.com/vinaypuppal/react-markdown-editor) so let me know if I've b0rked it somewhere.

// # h1
// ## h2
// ### h3
// #### h4
// ##### h5
// ###### h6


// ```
// const thisCode = 'hello world'
// const = () => {
//    return false
// }
// ```

// [linky](https://codevh.com/)

// - bullet1 
// - bullet2
// - bullet3 