import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLockers
} from '../../store/actions/locker';
import { isEmpty } from 'jvh-is-empty';
import {
    createPadlock
} from '../../store/actions/padlock';

// utils
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import Button from '../../components/Layout/Button/Button';
import PageLoader from '../../components/Layout/PageLoader/PageLoader';
import Input from '../../components/Layout/Input/Input';
import PasswordInput from '../../components/Layout/PasswordInput/PasswordInput';
import CustomDropdown from '../../components/Layout/CustomDropdown/CustomDropdown';
import MiniLoader from '../../components/Layout/MiniLoader/MiniLoader';
import Switch from '../../components/Layout/Switch/Switch';
import BulletCreator from '../../components/Padlocks/BulletCreator/BulletCreator';
const initialState = {
    title: '',
    username: '',
    password: '',
    notes: '',
    locker: '',
    hasTwoAuth: false,
    secondUsername: '',
    phone: '',
    encryptedBullets: [],
    lockerId: ''
};
const CreatePadlock = (props) => {
    const dispatch = useDispatch();
    const lockerState = useSelector((state) => state.locker);
    const { creating } = useSelector((state) => state.padlock);
    const [formData, setFormData] = useState({
        title: '',
        username: '',
        password: '',
        notes: '',
        locker: '',
        hasTwoAuth: false,
        secondUsername: '',
        phone: '',
        encryptedBullets: [],
        lockerId: ''
    });
    const [lockers, setLockers] = useState([]);
    // this dictates when to clear the children components (2auth switch and bullets and dropdown)
    const [clearChildren, setClearChildren] = useState(false);

    // Fetch the lockers if not present
    useEffect(() => {
        if (!lockerState.fetching && isEmpty(lockerState.lockers)) {
            dispatch(fetchLockers());
        }
    }, []);

    // watch for lockers to be fetched and if they are in state we re-format them for the dropdown
    useEffect(() => {
        if (!isEmpty(lockerState.lockers)) {
            setLockers([...lockerState.lockers.map((a, i) => {
                return {
                    name: a.lockerName,
                    value: a._id
                }
            })])
        }
    }, [lockerState.lockers]);

    const { title, username, password, notes, locker, hasTwoAuth, secondUsername, phone, encryptedBullets, lockerId } = formData;

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            lockerId,
            locker,
            title,
            username,
            password,
            notes,
            hasTwoAuth,
            secondUsername,
            phone,
            encryptedBullets
        };
        await dispatch(createPadlock(formData, clearForm));
    };

    const lockerSelectHandler = (data) => {
        setFormData({
            ...formData,
            locker: data.name,
            lockerId: data.value
        });
    };

    const onSwitchChange = (isTrue) => {
        setFormData({
            ...formData,
            hasTwoAuth: isTrue
        });
    };

    const onBulletChange = (bulletArr) => {
        // trim out the id's since we don't need them by deconstructing the traversed obj
        setFormData({
            ...formData,
            encryptedBullets: bulletArr.map(({ title, text }) => ({ title, text }))
        });
    };

    const clearForm = () => {
        setFormData(initialState);
        setClearChildren(true);
        setTimeout(() => {
            setClearChildren(false);
        }, 1000);
    };

    return (
        <article style={styles.root}>
            <Wrapper>
                <section className="large-shadow large-rad" style={styles.formCard}>
                    <h3 className="light-text">New Padlock</h3>
                    <form onSubmit={(e) => onSubmit(e)} style={styles.form}>
                        <div className="form-block">
                            <label className="input-label light-text">Locker *</label>
                            {lockerState.fetching && isEmpty(lockerState.lockers) &&
                                <Wrapper>
                                    <MiniLoader />
                                </Wrapper>
                            }
                            {!lockerState.fetching && !isEmpty(lockerState.lockers) && !isEmpty(lockers) &&
                                <CustomDropdown
                                    setDefault={clearChildren}
                                    handleChange={lockerSelectHandler}
                                    isPredefined={false}
                                    type="lockers"
                                    isSearchable={true}
                                    options={lockers}
                                    placeholder="Select a locker"
                                />
                            }
                            {!lockerState.fetching && isEmpty(lockerState.lockers) && isEmpty(lockers) &&
                                <p style={styles.emptyLockerMsg}>Create a locker to select one :)</p>
                            }
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Title *</label>
                            <Input
                                inputType="input"
                                value={title}
                                onChange={(e) => onChange(e)}
                                placeholder="Padlock Title"
                                name="title"
                                type="text"
                            />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Username/Email *</label>
                            <Input
                                inputType="input"
                                value={username}
                                onChange={(e) => onChange(e)}
                                placeholder="Username or Email"
                                name="username"
                                type="text"
                            />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Password *</label>
                            <PasswordInput
                                value={password}
                                onChange={(e) => onChange(e)}
                                name="password"
                                viewable
                                placeholder="Password"
                            />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Uses 2Auth</label>
                            <Switch setDefault={clearChildren} onSwitchChange={onSwitchChange} />
                        </div>
                        {hasTwoAuth &&
                            <>
                                <div className="form-block">
                                    <label className="input-label light-text">Backup Username/Email</label>
                                    <Input
                                        inputType="input"
                                        value={secondUsername}
                                        onChange={(e) => onChange(e)}
                                        placeholder="Username or Email"
                                        name="secondUsername"
                                        type="text"
                                    />
                                </div>
                                <div className="form-block">
                                    <label className="input-label light-text">Phone Number</label>
                                    <Input
                                        inputType="input"
                                        value={phone}
                                        onChange={(e) => onChange(e)}
                                        placeholder="(832)-334-9639"
                                        name="phone"
                                        type="tel"
                                    />
                                </div>
                            </>
                        }
                        <div className="form-block">
                            <label className="input-label light-text">Encrypted Bullets</label>
                            <BulletCreator setDefault={clearChildren} onBulletChange={onBulletChange} />
                        </div>
                        <div className="form-block">
                            <label className="input-label light-text">Notes</label>
                            <Input
                                inputType="textarea"
                                value={notes}
                                onChange={(e) => onChange(e)}
                                placeholder="Write some notes..."
                                name="notes"
                                rows="6"
                            />
                        </div>
                        <Wrapper styles={{ justifyContent: 'flex-end' }}>
                            <Button type="submit" loading={creating}>Submit</Button>
                        </Wrapper>
                    </form>
                </section>
            </Wrapper>
        </article>
    );
};

const styles = {
    root: {
        marginBottom: '4rem'
    },
    formCard: {
        width: '100%',
        maxWidth: '35rem',
        backgroundColor: Constants.main,
        padding: '1rem',
        margin: '2rem 1rem'
    },
    form: {

    },
    emptyLockerMsg: {
        color: 'white',
        margin: 0,
        padding: 0,
        fontSize: '.8rem',
        fontWeight: 600,
        opacity: '.8'
    }
};

export default CreatePadlock;