import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    verifyCode
} from '../../store/actions/code';
import { withRouter } from 'react-router-dom';
import {
    updateUser
} from '../../store/actions/auth';
import { isEmpty } from 'jvh-is-empty';

// utils
import Constants from '../../utils/Constants';

// components
import Wrapper from '../Layout/Wrapper/Wrapper';
import Button from '../Layout/Button/Button';
import Countdown from '../Countdown/Countdown';
import DigitCode from '../DigitCode/DigitCode';
import Input from '../Layout/Input/Input';
import CustomDropdown from '../Layout/CustomDropdown/CustomDropdown';
import PasswordInput from '../Layout/PasswordInput/PasswordInput';
import PasswordRules from '../PasswordRules/PasswordRules';

const UserSettings = (props) => {
    const dispatch = useDispatch();
    const { closeDialog, settingType, history } = props;
    const { codeLen, hasVerified, verifying } = useSelector((state) => state.code);
    const { updatingProfile, user, isAuth } = useSelector((state) => state.auth);
    const [code, setCode] = useState('');
    const [formData, setFormData] = useState({
        email: {
            emailInput: ''
        },
        security: {
            questionInput: '',
            answerInput: ''
        }
    });

    const onChange = (e) => setFormData({ ...formData, [settingType]: { ...formData[settingType], [e.target.name]: e.target.value } });

    // this func concatenates the key[values] as a whole string
    const keyValuesToStr = (obj) => {
        return Object.values(obj).join('');
    };

    const onCodeChange = (data) => {
        setCode(keyValuesToStr(data));
    };

    const onVerifySubmit = async () => {
        await dispatch(verifyCode(code, settingType, history));
    };

    const onSecurityQChange = (option) => {
        setFormData({ ...formData, [settingType]: { ...formData[settingType], questionInput: option.value } });
    };

    const renderForm = () => {
        if (hasVerified) {
            switch (settingType) {
                case 'email':
                    const { emailInput } = formData.email;
                    return (
                        <div className="form-block">
                            <label className="input-label light-text">New Email</label>
                            <Input
                                type="email"
                                inputType="input"
                                value={emailInput}
                                onChange={(e) => onChange(e)}
                                name="emailInput"
                                placeholder="new.email@yahoo.com"
                            />
                        </div>
                    );
                case 'security':
                    const { answerInput } = formData.security;
                    return (
                        <>
                            <div className="form-block">
                                <label className="input-label light-text">Security Question</label>
                                <CustomDropdown
                                    type="securityQuestions"
                                    handleChange={onSecurityQChange}
                                    isSearchable={false}
                                    isPredefined={false}
                                    options={Constants.securityQuestions}
                                    placeholder="Select a security question"
                                />
                            </div>
                            <div className="form-block">
                                <label className="input-label light-text">Answer</label>
                                <Input
                                    type="text"
                                    inputType="input"
                                    value={answerInput}
                                    onChange={(e) => onChange(e)}
                                    name="answerInput"
                                    placeholder="Your answer"
                                />
                            </div>
                        </>
                    );
                default: return null;
            }
        }
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        let newFormData = {}
        switch (settingType) {
            case 'email':
                const { emailInput } = formData.email;
                newFormData['email'] = emailInput; 
                break;
            case 'security':
                newFormData['security'] = {
                    question: formData.security.questionInput,
                    answer: formData.security.answerInput
                }
                break;
            default: return;
        }

        if (isAuth && !isEmpty(user)) {
            await dispatch(updateUser(newFormData, user._id));
        }
    };

    return (
        <form onSubmit={(e) => onSubmitHandler(e)}>
            {!hasVerified &&
                <div>
                    <p className="light-text" style={styles.countDownText}>Your code expires in 5 minutes</p>
                    <Wrapper>
                        <Countdown />
                    </Wrapper>
                    <Wrapper styles={{ margin: '1.5rem 0 .5rem 0' }}>
                        <DigitCode setChange={onCodeChange} codeLen={codeLen} />
                    </Wrapper>
                </div>
            }
            {hasVerified && renderForm()}
            <Wrapper styles={{ justifyContent: 'space-evenly' }}>
                <Button onClick={() => dispatch(closeDialog())} type="button">Close</Button>
                {!hasVerified ?
                    <Button onClick={() => onVerifySubmit()} loading={verifying} type="button">Verify</Button>
                    :
                    <Button onClick={(e) => onSubmitHandler(e)} loading={updatingProfile} type="button">Save</Button>
                }
            </Wrapper>
        </form>
    );
};

const styles = {
    countDownText: {
        textAlign: 'center',
        padding: 0,
        margin: '.2rem 0 1rem 0',
        fontSize: '.8rem',
        opacity: '.8'
    }
};

export default withRouter(UserSettings);