import * as types from './types/padlock';
import axios from 'axios';
import { setAlert } from './alert';
import { closeDialog } from './dialog';
import Constants from '../../utils/Constants';
import { encrypt } from '../../utils/crypto';
import { isEmpty } from 'jvh-is-empty';

const PROD_URL = Constants.PROD_URL;

//! Fetch padlocks
export const fetchPadlocks = (lockerId) => async (dispatch) => {
    await dispatch({ type: types.FETCH_PADLOCKS_PENDING });
    await dispatch(fetchPadlocksReq(lockerId));
};

export const fetchPadlocksReq = (lockerId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.get(`${PROD_URL}/api/padlock/fetch_padlocks/${lockerId}`, config)
        .then(async (res) => {
            await dispatch({
                type: types.FETCH_PADLOCKS_SUCCESS,
                payload: res.data.map((a) => ({
                    _id: a._id,
                    title: a.title,
                    notes: a.notes,
                    locker: a.locker,
                    lockerId: a.lockerId,
                    createdAt: a.createdAt,
                    updatedAt: a.updatedAt,
                    hasTwoAuth: a.hasTwoAuth,
                    username: encrypt(a.username),
                    password: encrypt(a.password),
                    secondUsername: !isEmpty(a.secondUsername) ? encrypt(a.secondUsername) : '',
                    phone: !isEmpty(a.phone) ? encrypt(a.phone) : '',
                    encryptedBullets: !isEmpty(a.encryptedBullets) ? a.encryptedBullets.map((bull) => ({
                        title: bull.title,
                        text: encrypt(bull.text)
                    })) : []
                }))
            });
        })
        .catch(async (err) => {
            const error = err.response.data.serverMsg;
            // if (error) {
            //     await dispatch(setAlert(error, 'error'));
            // }
            await dispatch({
                type: types.FETCH_PADLOCKS_FAIL
            });
        });
};

//! Create Padlock
export const createPadlock = (formData, clearForm) => async (dispatch) => {
    if (isEmpty(formData.locker) || isEmpty(formData.lockerId)) {
        return dispatch(setAlert('You need to assign this padlock to a locker', 'error'));
    }
    await dispatch({ type: types.CREATE_PADLOCK_PENDING });
    await dispatch(createPadlockReq({ ...formData }, clearForm));
};

export const createPadlockReq = (formData, clearForm) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    // ! NEED TO DICTATE THE PADLOCK AFTER ADDING IT
    await axios.post(`${PROD_URL}/api/padlock/create_padlock/${formData.lockerId}`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.CREATE_PADLOCK_SUCCESS,
                payload: res.data.padlock
            });
            clearForm();
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.CREATE_PADLOCK_FAIL
            });
        });
};

//! Fetch a single padlock
export const fetchPadlock = (padlockId) => async (dispatch) => {
    await dispatch({ type: types.FETCH_PADLOCK_PENDING });
    await dispatch(fetchPadlockReq(padlockId));
};

export const fetchPadlockReq = (padlockId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.get(`${PROD_URL}/api/padlock/fetch_padlock/${padlockId}`, config)
        .then(async (res) => {
            const pl = res.data;
            await dispatch({
                type: types.FETCH_PADLOCK_SUCCESS,
                payload: {
                    _id: pl._id,
                    title: pl.title,
                    notes: pl.notes,
                    locker: pl.locker,
                    lockerId: pl.lockerId,
                    createdAt: pl.createdAt,
                    updatedAt: pl.updatedAt,
                    hasTwoAuth: pl.hasTwoAuth,
                    username: encrypt(pl.username),
                    password: encrypt(pl.password),
                    secondUsername: !isEmpty(pl.secondUsername) ? encrypt(pl.secondUsername) : '',
                    phone: !isEmpty(pl.phone) ? encrypt(pl.phone) : '',
                    encryptedBullets: !isEmpty(pl.encryptedBullets) ? pl.encryptedBullets.map((bull) => ({
                        _id: bull._id,
                        title: bull.title,
                        text: encrypt(bull.text)
                    })) : []
                }
            });
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.FETCH_PADLOCK_FAIL
            });
        });
};

//! Update padlock
export const updatePadlock = (formData, padlockId, setIsEditing) => async (dispatch) => {
    await dispatch({ type: types.UPDATE_PADLOCK_PENDING });
    await dispatch(updatePadlockReq({ ...formData }, padlockId, setIsEditing));
};

export const updatePadlockReq = (formData, padlockId, setIsEditing) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.put(`${PROD_URL}/api/padlock/update_padlock/${padlockId}`, formData, config)
        .then(async (res) => {
            setIsEditing(false);
            const pl = res.data.padlock;
            await dispatch({
                type: types.UPDATE_PADLOCK_SUCCESS,
                payload: {
                    _id: pl._id,
                    title: pl.title,
                    notes: pl.notes,
                    locker: pl.locker,
                    lockerId: pl.lockerId,
                    createdAt: pl.createdAt,
                    updatedAt: pl.updatedAt,
                    hasTwoAuth: pl.hasTwoAuth,
                    username: encrypt(pl.username),
                    password: encrypt(pl.password),
                    secondUsername: !isEmpty(pl.secondUsername) ? encrypt(pl.secondUsername) : '',
                    phone: !isEmpty(pl.phone) ? encrypt(pl.phone) : '',
                    encryptedBullets: !isEmpty(pl.encryptedBullets) ? pl.encryptedBullets.map(({
                        _id, title, text
                    }) => ({
                        _id, title, text: encrypt(text)
                    })) : []
                }
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.UPDATE_PADLOCK_FAIL
            });
        });
};

//! Delete padlock 
export const deletePadlock = (padlockId, redirect) => async (dispatch) => {
    await dispatch({ type: types.DELETE_PADLOCK_PENDING });
    await dispatch(deletePadlockReq(padlockId, redirect));
};
export const deletePadlockReq = (padlockId, redirect) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.delete(`${PROD_URL}/api/padlock/delete_padlock/${padlockId}`, config)
        .then(async (res) => {
            await dispatch({
                type: types.DELETE_PADLOCK_SUCCESS,
                payload: padlockId
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            redirect();
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.DELETE_PADLOCK_FAIL
            });
        });
};

//! Move padlocks
export const movePadlocks = (formData) => async (dispatch) => {
    await dispatch({ type: types.MOVE_PADLOCKS_PENDING });
    await dispatch(movePadlocksReq(formData));
};

export const movePadlocksReq = (formData) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    // passing in the formData lockerId so we can verify authority in the back end
    await axios.put(`${PROD_URL}/api/padlock/move_padlocks/${formData.lockerId}`, formData, config)
        .then(async (res) => {
            await dispatch(closeDialog());
            await dispatch({
                type: types.MOVE_PADLOCKS_SUCCESS,
                payload: formData.padlockIdArr
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            await dispatch(closeDialog());
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.MOVE_PADLOCKS_FAIL
            });
        });
};
