import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';

// utils
import Constants from '../../utils/Constants';

// components
import Iconify from '../Layout/Iconify/Iconify';

const DigitCode = (props) => {
    const { codeLen, setChange } = props;
    const [splitValues, setSplitValues] = useState({});

    // select the first input on first initial load
    useEffect(() => {
        if (codeLen > 3) {
            const firstInput = document.querySelector(`input[name=index-0]`);
            if (firstInput !== null) {
                // needed to set a timeout because we were selecting before inputs were rendered. 
                setTimeout(() => {
                    firstInput.focus();
                }, 1000);
            }
        }
    }, [codeLen]);

    // extract the keys to create a form based on how man codes there will need to be inputted
    useEffect(() => {
        if (codeLen > 3) {
            let defaultKeys = {};
            for (let i = 0; i < codeLen; i++) {
                defaultKeys[i] = '';
            }
            setSplitValues(defaultKeys);
        }
    }, [codeLen]);

    // when values change we update them 
    useEffect(() => {
        setChange(splitValues);
    }, [splitValues]);

    const onChange = (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        setSplitValues({ ...splitValues, [fieldIndex]: value.toUpperCase() });

        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < codeLen) {
                const nextSibling = document.querySelector(`input[name=index-${parseInt(fieldIndex, 10) + 1}]`);

                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    };

    const onKeyDownHandler = (e) => {
        const { name, value } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        const keyCode = e.keyCode;

        setSplitValues((prevState) => {
            // check if prevState key value was empty prior hitting backspace and we hit backspace again 
            // if it was then we change the focused index - 1
            if (isEmpty(prevState[fieldIndex]) && keyCode === 8) {
                // if we are still in index... meaning we arent at index 0 and can still go back.
                if (parseInt(fieldIndex, 10) > 0) {
                    // focus on next input element
                    // SOURCE: https://linguinecode.com/post/focus-next-input-in-react
                    const previousSibling = document.querySelector(`input[name=index-${parseInt(fieldIndex, 10) - 1}]`);
                    // focus on previous sibling
                    if (previousSibling !== null) {
                        previousSibling.focus();
                    }
                }
            }
            return {
                ...splitValues
            }
        });
    };

    // renders dashes in between boxes
    const renderDashes = (i) => {
        if (i + 1 === codeLen) {
            return null;
        } else {
            return <Iconify style={styles.dashIcon} iconName="dash" />
        }
    };

    const renderCodeInputs = () => {
        if (codeLen <= 3) {
            return null
        }
        return Array(codeLen).fill().map((_, i) => {
            return (
                <div key={i}>
                    <input
                        name={`index-${i}`}
                        onChange={(e) => onChange(e)}
                        type="text"
                        style={styles.digitInput}
                        className="large-rad large-shadow"
                        maxLength={1}
                        onKeyDown={(e) => onKeyDownHandler(e)}
                        value={splitValues[i]}
                        autoComplete="off"
                    />
                    {renderDashes(i)}
                </div>
            );
        });
    };

    const styles = {
        digitFrame: {
            display: 'flex',

        },
        digitInput: {
            width: '2.5rem',
            height: '4rem',
            margin: '0 .1rem',
            textAlign: 'center',
            fontSize: '2.5rem',
            border: '1px solid',
            borderColor: 'white',
            outline: 'none',
            backgroundColor: 'white'
        },
        dashIcon: {
            color: Constants.secondary,
            fontSize: '1.4rem',
            padding: 0,
            margin: 0
        }
    };

    return (
        <main style={styles.digitFrame}>
            {renderCodeInputs()}
        </main>
    );
};

export default DigitCode;