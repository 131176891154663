import React, { useEffect, useState } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { setAlert } from '../../store/actions/alert';
import {
    changePassword
} from '../../store/actions/auth';

// utils
import { decrypt } from '../../utils/crypto';
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import PasswordInput from '../../components/Layout/PasswordInput/PasswordInput';
import PasswordRules from '../../components/PasswordRules/PasswordRules';
import Button from '../../components/Layout/Button/Button';
import Divider from '../../components/Layout/Divider/Divider';

const ChangePassword = (props) => {
    const { hasVerified } = useSelector((state) => state.code);
    const { authLoading, isAuth, user, changingPassword } = useSelector((state) => state.auth);
    const { history } = props;
    const { userId } = useParams();
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const dispatch = useDispatch();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    // if the user is not verified and the parameter userId is not equal to the authenticated userId
    // redirect back to the account
    //! THINK OF A LESS DRY WAY TO DO THIS
    useEffect(() => {
        if (!hasVerified) {
            history.push('/account');
        } else if (!authLoading && !isAuth) {
            history.push('/account');
        } else if (!authLoading && isAuth && user._id !== decrypt(userId)) {
            history.push('/account');
        }
    }, [hasVerified, authLoading, user, isAuth]);

    const { oldPassword, newPassword, confirmPassword } = formData;

    // validates password requirements
    const handlePassValidation = () => {
        let isValid = true;
        let objectKeys = {
            1: newPassword.length >= 6,
            2: /[a-z]/.test(newPassword),
            3: /[0-9]/.test(newPassword),
            4: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword),
            5: newPassword === confirmPassword,
            6: /[A-Z]/.test(newPassword)
        };

        for (let val in objectKeys) {
            if (!objectKeys[val]) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (!handlePassValidation()) {
            return await dispatch(setAlert('Make sure you check all the requirements for the new password', 'error', 5000));
        }

        await dispatch(changePassword(formData, user.email, decrypt(userId), history));
    };

    return (
        <Wrapper styles={{ margin: '3rem 0' }}>
            <main className="large-shadow large-rad" style={styles.formWrap}>
                <Wrapper>
                    <h1 className="light-text" style={styles.formTitle}>Change password</h1>
                </Wrapper>
                <form onSubmit={(e) => onSubmitHandler(e)} style={styles.form}>
                    <div className="form-block">
                        <label className="input-label light-text">Old Password</label>
                        <PasswordInput
                            name="oldPassword"
                            value={oldPassword}
                            onChange={(e) => onChange(e)}
                            placeholder="Old password"
                        />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">New Password</label>
                        <PasswordInput
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => onChange(e)}
                            placeholder="New password"
                        />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Confirm Password</label>
                        <PasswordInput
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => onChange(e)}
                            placeholder="Old password"
                        />
                    </div>
                    <Wrapper styles={{ justifyContent: 'flex-end' }}>
                        <Button type="submit" loading={changingPassword}>
                            Save
                        </Button>
                    </Wrapper>
                </form>
                <Divider vertical style={styles.divider} />
                <PasswordRules
                    isSmall
                    style={styles.passwordRules}
                    passwordVerify={confirmPassword}
                    password={newPassword}
                />
            </main>
        </Wrapper>
    );
};

const styles = {
    formWrap: {
        backgroundColor: Constants.main,
        padding: '2rem 1rem',
        width: '90%',
        maxWidth: '50rem',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexFlow: 'row wrap',
        overflowY: 'hidden',
        alignItems: 'flex-start'
    },
    formTitle: {
        padding: 0,
        margin: '0 0 2rem 0'
    },
    form: {
        width: '100%',
        maxWidth: '20rem'
    },
    divider: {
        height: '25rem'
    },
    passwordRules: {
        width: '100%',
        maxWidth: '20rem'
    }
};

export default withRouter(ChangePassword);