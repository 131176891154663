import React from 'react';
import {
    FiSettings,
    FiEdit,
    FiMoreHorizontal,
    FiMoreVertical
} from 'react-icons/fi';
import {
    GoChevronDown,
    GoChevronUp,
    GoDash
} from 'react-icons/go';
import {
    AiOutlineClose,
    AiOutlinePlus,
    AiFillUnlock,
    AiFillLock,
    AiFillWarning,
    AiFillBug,
    AiOutlineCheckCircle
} from 'react-icons/ai';
import {
    FaTrash,
    FaCoffee
} from 'react-icons/fa';
import {
    MdOutlineSaveAlt
} from 'react-icons/md';
import {
    ImArrowRight2,
    ImArrowLeft2
} from 'react-icons/im';
import {
    MdDriveFileMoveOutline
} from 'react-icons/md';
import {
    HiOutlineClipboardCopy
} from 'react-icons/hi';
import {
    BiWorld,
    BiErrorAlt
} from 'react-icons/bi';
import {
    BsPaypal,
} from 'react-icons/bs';
import {
    VscCircleLargeFilled
} from 'react-icons/vsc';

const Iconify = (props) => {
    const returnIcon = () => {
        switch (props.iconName) {
            case 'settings':
                return <FiSettings style={{ ...props.style }} />
            case 'edit':
                return <FiEdit name="edit" style={{ ...props.style }} />
            case 'upArrow':
                return <GoChevronUp name="edit" style={{ ...props.style }} />
            case 'downArrow':
                return <GoChevronDown name="edit" style={{ ...props.style }} />
            case 'close':
                return <AiOutlineClose name="close" style={{ ...props.style }} />
            case 'add':
                return <AiOutlinePlus name="add" style={{ ...props.style }} />
            case 'delete':
                return <FaTrash name="delete" style={{ ...props.style }} />
            case 'save':
                return <MdOutlineSaveAlt name="save" style={{ ...props.style }} />
            case 'lock':
                return <AiFillLock name="lock" style={{ ...props.style }} />
            case 'unlock':
                return <AiFillUnlock name="unlock" style={{ ...props.style }} />
            case 'move':
                return <MdDriveFileMoveOutline name="move" style={{ ...props.style }} />
            case 'more':
                return <FiMoreHorizontal name="more" style={{ ...props.style }} />
            case 'moreVert':
                return <FiMoreVertical name="moreVert" style={{ ...props.style }} />
            case 'rightArrow':
                return <ImArrowRight2 name="rightArrow" style={{ ...props.style }} />
            case 'leftArrow':
                return <ImArrowLeft2 name="rightArrow" style={{ ...props.style }} />
            case 'copy':
                return <HiOutlineClipboardCopy name="copy" style={{ ...props.style }} />
            case 'warning':
                return <AiFillWarning name="warning" style={{ ...props.style }} />
            case 'dash':
                return <GoDash name="dash" style={{ ...props.style }} />
            case 'bug':
                return <AiFillBug name="bug" style={{ ...props.style }} />
            case 'paypal':
                return <BsPaypal name="paypal" style={{ ...props.style }} />
            case 'world':
                return <BiWorld name="world" style={{ ...props.style }} />
            case 'dot':
                return <VscCircleLargeFilled name="dot" style={{ ...props.style }} />
            case 'error':
                return <BiErrorAlt name="error" style={{ ...props.style }} />
            case 'success':
                return <AiOutlineCheckCircle name="success" style={{ ...props.style }} />
            case 'coffee':
                return <FaCoffee name="coffee" style={{ ...props.style }} />

            default: return null;
        }
    };

    return returnIcon();
};

export default Iconify;