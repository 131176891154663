//! CREATE BACKLOG TYPES
export const CREATE_BACKLOG_PENDING = 'CREATE_BACKLOG_PENDING';
export const CREATE_BACKLOG_FAIL = 'CREATE_BACKLOG_FAIL';
export const CREATE_BACKLOG_SUCCESS = 'CREATE_BACKLOG_SUCCESS';

//! FETCH BACKLOGS TYPES
export const FETCH_BACKLOGS_PENDING = 'FETCH_BACKLOGS_PENDING';
export const FETCH_BACKLOGS_FAIL = 'FETCH_BACKLOGS_FAIL';
export const FETCH_BACKLOGS_SUCCESS = 'FETCH_BACKLOGS_SUCCESS';

//! UPDATE BACKLOG TYPES
export const UPDATE_BACKLOG_PENDING = 'UPDATE_BACKLOG_PENDING';
export const UPDATE_BACKLOG_FAIL = 'UPDATE_BACKLOG_FAIL';
export const UPDATE_BACKLOG_SUCCESS = 'UPDATE_BACKLOG_SUCCESS';

//! FETCH SINGLE BACKLOG TYPES
export const FETCH_BACKLOG_PENDING = 'FETCH_BACKLOG_PENDING';
export const FETCH_BACKLOG_FAIL = 'FETCH_BACKLOG_FAIL';
export const FETCH_BACKLOG_SUCCESS = 'FETCH_BACKLOG_SUCCESS';