import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    reEnterPassword
} from '../../../store/actions/auth';

// utils

// components
import Button from '../../Layout/Button/Button';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import PasswordInput from '../../Layout/PasswordInput/PasswordInput';

const Password = (props) => {
    const { closeDialog, history, setHasVerified } = props;
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const { securityLoading } = useSelector((state) => state.auth);

    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(reEnterPassword(password, history, setHasVerified));
    };

    return (
        <form onSubmit={(e) => onSubmit(e)}>
            <p style={styles.formSubTitle} className="light-text">Please enter your password before continuing</p>
            <div className="form-block">
                <label className="light-text input-label">Your Password</label>
                <PasswordInput
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                />
            </div>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <Button onClick={() => {
                    dispatch(closeDialog());
                    history.goBack();
                }} type="button">Close</Button>
                <Button loading={securityLoading} type="submit">Verify</Button>
            </Wrapper>
        </form>
    );
};

const styles = {
    formSubTitle: {
        textAlign: 'center'
    }
};

export default withRouter(Password);