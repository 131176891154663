import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    logout
} from '../../../store/actions/auth';
import {
    openDialog
} from '../../../store/actions/dialog';
import { isEmpty } from 'jvh-is-empty';
import Undercover from '../../../assets/undercover.jpeg'

// components
import Wrapper from '../Wrapper/Wrapper';
import TerminateAccount from '../../TerminateAccount/TerminateAccount';
import Button from '../Button/Button';
import CreateLocker from '../../Lockers/CreateLocker/CreateLocker';
import MobileNav from '../MobileNav/MobileNav';

// utils
import Constants from '../../../utils/Constants';

const Navbar = ({ history }) => {
    const { authLoading, isAuth, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [location, setLocation] = useState(window.location.pathname);
    const [selectedLinks, setSelectedLinks] = useState({
        locker: true,
        account: false,
        padlocks: false,
        create_padlock: false,
        reports: false,
        new_backlog: false
    });
    const [isMobileSize, setIsMobileSize] = useState(false);

    useEffect(() => {
        // returns only the first word after the first slash
        // SOURCE: https://stackoverflow.com/questions/5735483/regex-to-get-first-word-after-slash-in-url
        setLocation(`/${window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')}`);
    }, [window.location.pathname])

    useEffect(() => {
        const returnIsMobile = () => {
            if (window.innerWidth <= 980) {
                setIsMobileSize(true);
            } else if (window.innerWidth > 980) {
                setIsMobileSize(false);
            }
        };
        window.addEventListener('resize', returnIsMobile);
        return () => window.removeEventListener('resize', returnIsMobile)
    }, []);

    useEffect(() => {
        switch (location) {
            case '/locker':
                setSelectedLinks({
                    locker: true,
                    account: false,
                    padlocks: false,
                    create_padlock: false,
                    reports: false,
                    new_backlog: false
                });
                break;
            case '/account':
                setSelectedLinks({
                    locker: false,
                    account: true,
                    padlocks: false,
                    create_padlock: false,
                    reports: false,
                    new_backlog: false
                });
                break;
            case '/padlocks':
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: true,
                    create_padlock: false,
                    reports: false,
                    new_backlog: false
                });
                break;
            case '/padlock':
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: false,
                    create_padlock: true,
                    reports: false,
                    new_backlog: false
                });
                break;
            case '/create_padlock':
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: false,
                    create_padlock: true,
                    reports: false,
                    new_backlog: false
                });
                break;
            case '/reports':
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: false,
                    create_padlock: false,
                    reports: true,
                    new_backlog: false
                });
                break;
            case '/new_backlog':
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: false,
                    create_padlock: false,
                    reports: false,
                    new_backlog: true
                });
                break;
            default:
                setSelectedLinks({
                    locker: false,
                    account: false,
                    padlocks: false,
                    create_padlock: false,
                    reports: false,
                    new_backlog: false
                });
                return;
        }
    }, [location]);

    const createLockerForm = (closeDialog) => <CreateLocker closeDialog={closeDialog} />;

    const terminateAccount = (closeDialog) => <TerminateAccount closeDialog={closeDialog} />;

    const renderDynamicLinks = () => {
        switch (location) {
            case '/locker':
                return <Button onClick={() => dispatch(openDialog('Create Locker', createLockerForm, 30))} type="button">New Locker</Button>
            case '/create_padlock':
                return <Button onClick={() => dispatch(openDialog('Create Locker', createLockerForm, 30))} type="button">New Locker</Button>
            case '/account':
                return <Button onClick={() => dispatch(openDialog('Terminate Account', terminateAccount, 30))} type="button">Terminate</Button>
            case '/reports':
                return <Button onClick={() => { history.push('/backlogs') }}>Backlogs</Button>
            default: return;
        }
    };

    const styles = {
        nav: {
            height: '4rem',
            backgroundColor: Constants.main,
        },
        navLinkWrap: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '1rem'
        },
        navLinks: {
            textDecoration: 'none',
            margin: '0 .8rem',
            fontWeight: 600,
            fontSize: '1.1rem',
            cursor: 'pointer',
        },
        logo: {
            height: '4rem',
            marginLeft: '1rem'
        },
        logoutLink: {
            color: 'white'
        }
    };

    const renderAdminLink = () => {
        if (authLoading) {
            return null;
        } else if (!authLoading && !isAuth) {
            return null;
        } else if (!authLoading && !isEmpty(user) && !user.isAdmin) {
            return null;
        } else if (!authLoading && isAuth && !isEmpty(user) && user.isAdmin) {
            return (
                <Link
                    to="/new_backlog"
                    className="text-white"
                    style={{
                        ...styles.navLinks,
                        color: selectedLinks.new_backlog ? Constants.secondary : 'white'
                    }}
                >Backlog</Link>
            );
        }
    };

    const authLinks = (
        <>
            <a
                href="#!"
                style={{
                    ...styles.navLinks,
                    ...styles.logoutLink
                }}
                onClick={() => dispatch(logout(history))}
                className="text-white"
            >
                Logout
            </a>
            <Link
                to="/locker"
                className="text-white"
                style={{
                    ...styles.navLinks,
                    color: selectedLinks.locker ? Constants.secondary : 'white'
                }}
            >Locker</Link>
            <Link
                to="/account"
                className="text-white"
                style={{
                    ...styles.navLinks,
                    color: selectedLinks.account ? Constants.secondary : 'white'
                }}
            >Account</Link>
            <Link
                to="/create_padlock"
                className="text-white"
                style={{
                    ...styles.navLinks,
                    color: selectedLinks.create_padlock ? Constants.secondary : 'white'
                }}
            >New Padlock</Link>
            <Link
                to="/reports"
                className="text-white"
                style={{
                    ...styles.navLinks,
                    color: selectedLinks.reports ? Constants.secondary : 'white'
                }}
            >Reports</Link>
            {renderAdminLink()}
        </>
    );

    const renderNavType = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isMobileSize || window.innerWidth <= 980) {
            return (
                <section style={styles.navLinkWrap}>
                    <MobileNav location={location} authLoading={authLoading} isAuth={isAuth} user={user} history={history} />
                </section>
            );
        } else {
            return (
                <section style={styles.navLinkWrap}>
                    {!authLoading && isAuth && !isEmpty(user) && authLinks}
                    {renderDynamicLinks()}
                </section>
            );
        }
    };

    return (
        <nav style={styles.nav} className="small-shadow">
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                {/* {!authLoading && isAuth && !isEmpty(user) && <NameBadge letterAmount={1} user={user} />} */}
                <img src={Undercover} alt="logo" style={styles.logo} />
                {!authLoading && isAuth && !isEmpty(user) && renderNavType()}
            </Wrapper>
        </nav>
    );
};

export default withRouter(Navbar);