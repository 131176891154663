import * as types from '../actions/types/locker';
import * as filterTypes from '../actions/types/lockerFilter';

const initialState = {
    fetching: false,
    deleting: false,
    updating: false,
    creating: false,
    lockers: [],
    locker: null
};

const alertReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.FETCH_LOCKERS_FAIL:
        case types.FETCH_LOCKER_FAIL:
            return {
                ...state,
                fetching: false,
                lockers: [],
                locker: null
            };
        case types.FETCH_LOCKERS_PENDING:
        case types.FETCH_LOCKER_PENDING:
            return {
                ...state,
                fetching: true,
                lockers: [],
                locker: null
            };
        case types.FETCH_LOCKERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                lockers: [...payload.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))]
            };
        case types.FETCH_LOCKER_SUCCESS:
            return {
                ...state,
                fetching: false,
                locker: payload
            };
        case types.CREATE_LOCKER_FAIL:
            return {
                ...state,
                creating: false
            };
        case types.CREATE_LOCKER_PENDING:
            return {
                ...state,
                creating: true
            };
        case types.CREATE_LOCKER_SUCCESS:
            return {
                ...state,
                creating: false,
                lockers: [payload, ...state.lockers]
            };
        case filterTypes.DATE_SUCCESS:
        case filterTypes.INPUT_SUCCESS:
        case filterTypes.NAME_SUCCESS:
        case filterTypes.CLEAR_FILTER:
            return {
                ...state,
                lockers: [...payload]
            };
        case types.UPDATE_LOCKER_FAIL:
            return {
                ...state,
                updating: false
            };
        case types.UPDATE_LOCKER_PENDING:
            return {
                ...state,
                updating: true
            };
        case types.UPDATE_LOCKER_SUCCESS:
            // find index of current project
            let foundLockerIndx = state.lockers.findIndex((locker) => locker._id === payload._id);
            state.lockers.splice(foundLockerIndx, 1, payload);
            return {
                ...state,
                updating: false,
                lockers: [...state.lockers]
            };
        case types.DELETE_LOCKER_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_LOCKER_PENDING:
            return {
                ...state,
                deleting: true
            };
        case types.DELETE_LOCKER_SUCCESS:
            return {
                ...state,
                deleting: false,
                lockers: state.lockers.filter((a) => a._id !== payload)
            };
        default:
            return state;
    }
};
export default alertReducer;