import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    createReport
} from '../../store/actions/report';

// utils
import Constants from '../../utils/Constants';

// components
import Wrapper from '../../components/Layout/Wrapper/Wrapper';
import Input from '../../components/Layout/Input/Input';
import Button from '../../components/Layout/Button/Button';
import CustomDropdown from '../../components/Layout/CustomDropdown/CustomDropdown';
import Divider from '../../components/Layout/Divider/Divider';
import Iconify from '../../components/Layout/Iconify/Iconify';
import RoundButton from '../../components/Layout/RoundButton/RoundButton';

const ReportBug = (props) => {
    // const { } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        reportType: '',
        message: ''
    });
    const { isSending } = useSelector((state) => state.report);
    const [renderMobileView, setRenderMobileView] = useState(false);

    useEffect(() => {
        
        const handleRules = () => {
            if (window.innerHeight < 766 || window.innerWidth < 619) {
                setRenderMobileView(true);
            } else {
                setRenderMobileView(false);
            }
        };
        window.addEventListener('resize', handleRules)
        return () => window.removeEventListener('resize', handleRules);
    }, []);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(createReport(formData));
    };

    const onTypeSelect = (option) => {
        setFormData({ ...formData, reportType: option.value });
    };

    const onWebsiteSelect = () => {
        window.open('https://codevh.com', '_blank');
    };
    const onPaypalSelect = () => {
        window.open('https://www.paypal.com/paypalme/jmvanhorn', '_blank');
    };

    const beforeMobile = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        MozTransform: 'translate(-50%, -50%)',
        OTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        padding: '1.5rem',
        width: '90%',
        maxWidth: '35rem',
        backgroundColor: Constants.main
    };

    const afterMobile = {
        padding: '1.5rem',
        width: '90%',
        maxWidth: '35rem',
        backgroundColor: Constants.main,
        margin: '2rem 0 3rem 0'
    }

    const styles = {
        root: renderMobileView ? { ...afterMobile } : { ...beforeMobile },
        title: {
            textAlign: 'center',
            fontSize: '2rem',
            padding: 0,
            margin: 0
        },
        donateText: {
            textAlign: 'center',
            padding: 0,
            margin: '0 0 1rem 0',
            fontSize: '1rem'
        },
        socialBtn: {
            color: Constants.secondary,
            cursor: 'pointer',
            margin: '0 .8rem'
        }
    };

    const returnContent = () => {
        return (
            <article className="large-shadow large-rad" style={styles.root}>
                <h3 style={styles.title} className="light-text">Contact Our Team!</h3>
                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-block">
                        <label className="input-label light-text">Report Type</label>
                        <CustomDropdown
                            handleChange={onTypeSelect}
                            placeholder="Select a report type"
                            options={Constants.reportTypes}
                            isSearchable={false}
                            isPredefined={false}
                            type="reportTypes"
                        />
                    </div>
                    <div className="form-block">
                        <label className="input-label light-text">Your Message</label>
                        <Input
                            inputType="textarea"
                            onChange={(e) => onChange(e)}
                            placeholder="Describe your report..."
                            rows="7"
                            name="message"
                        />
                    </div>
                    <p style={styles.donateText} className="light-text">Want To Connect Or Donate?</p>
                    <Wrapper>
                        <RoundButton onClick={onWebsiteSelect} style={styles.socialBtn}>
                            <Iconify iconName="world" />
                        </RoundButton>
                        <RoundButton onClick={onPaypalSelect} style={styles.socialBtn}>
                            <Iconify iconName="paypal" />
                        </RoundButton>
                    </Wrapper>
                    <Divider style={{ margin: '1rem 0' }} />
                    <Wrapper styles={{ justifyContent: 'flex-end' }}>
                        <Button loading={isSending} type="submit">Send</Button>
                    </Wrapper>
                </form>
            </article>
        );
    };

    return renderMobileView ? <Wrapper>{returnContent()}</Wrapper> : returnContent();
};

export default ReportBug;