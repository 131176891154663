import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';

// components
import Checkbox from '../Layout/Checkbox/Checkbox';
import Wrapper from '../Layout/Wrapper/Wrapper';
import Divider from '../Layout/Divider/Divider';

// utils
import Constants from '../../utils/Constants';

const PasswordRules = (props) => {
    const { password, passwordVerify, isSmall, style } = props;  

    const checkboxCheckedHandler = (password, passwordVerify, rule) => {
        // validates the password is of length
        if (password.length <= 5 && rule === 'Contains 6 Characters') {
            return false;
        } else if (password.length >= 6 && rule === 'Contains 6 Characters') {
            return true;
        }
        // checks for a lowercase character
        if (!/[a-z]/.test(password) && rule === '1 Lowercase') {
            return false;
        } else if (/[a-z]/.test(password) && rule === '1 Lowercase') {
            return true;
        }
        // checks for an uppercase character
        if (!/[A-Z]/.test(password) && rule === '1 Uppercase') {
            return false;
        } else if (/[A-Z]/.test(password) && rule === '1 Uppercase') {
            return true;
        }
        // checks if a number is passed in
        if (!/[0-9]/.test(password) && rule === '1 Number') {
            return false;
        } else if (/[0-9]/.test(password) && rule === '1 Number') {
            return true;
        }
        // validates for special characters
        if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) && rule === '1 Special Character') {
            return false;
        } else if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) && rule === '1 Special Character') {
            return true;
        }
        // validates if password and password verify are the same
        if (password !== passwordVerify && rule === 'Passwords Match') {
            return false;
        } else if (password === passwordVerify && rule === 'Passwords Match' && !isEmpty(password)) {
            return true;
        }
    };

    const renderPassReqs = () => {
        return Object.values(Constants.passwordReq).map((req, i) => {
            let isChecked;
            isChecked = checkboxCheckedHandler(password, passwordVerify, req.label);
            return (
                <div key={i + 1} style={styles.checkWrap}>
                    <Checkbox
                        type="passwordReq"
                        checked={isChecked}
                        disabled={true}
                        id={`${req.label}`}
                        index={i}
                        style={{ }}
                    />
                    <label style={styles.checkBoxLabel} className="light-text" htmlFor={`${req.label}`}>{req.label}</label>
                </div>
            )
        });
    };

    const styles = !isSmall ? {
        root: {
            width: '100%',
            maxWidth: '23rem',
            marginTop: '1.5rem',
            backgroundColor: Constants.bodyBg,
        },
        title: {
            fontSize: '1.4rem',
            margin: 0,
            padding: 0
        },
        // checkbox and label wrapper
        checkWrap: {
            display: 'flex',
            marginBottom: '2rem',
            alignItems: 'center'
        },
        // label for each checkbox
        checkBoxLabel: {
            fontSize: '1.2rem'
        }
    } : {
        root: {
            ...style,
            marginTop: '1.5rem',
            backgroundColor: Constants.bodyBg,
            padding: '1rem .5rem'
        },
        title: {
            fontSize: '1.2rem',
            margin: '0 0 1rem 0',
            padding: 0
        },
        // checkbox and label wrapper
        checkWrap: {
            display: 'flex',
            marginBottom: '1.5rem',
            alignItems: 'center'
        },
        // label for each checkbox
        checkBoxLabel: {
            fontSize: '1rem'
        }
    }

    return (
        <div className="large-rad small-shadow" style={styles.root}>
            <h3 style={styles.title} className="light-text">Password Requirements</h3>
            {!isSmall && <Divider style={{ margin: '1rem 0' }} />}
            {renderPassReqs()}
        </div>
    );
};

export default PasswordRules;