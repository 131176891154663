import React from 'react';

// utils
import Constants from '../../../utils/Constants';

const Divider = (props) => {
    const styles = {
        divider: {
            width: props.vertical ? '.1rem' : '100%',
            height: props.vertical && '100%',
            border: '1px solid',
            borderColor: Constants.secondary,
            borderRadius: '4px'
        }
    };

    return <div style={{ ...styles.divider, ...props.style }}></div>
};

export default Divider;