import * as types from './types/backlog';
import { isEmpty } from 'jvh-is-empty';
import { setAlert } from './alert';
import axios from 'axios';
import { openDialog, closeDialog } from './dialog';
import Constants from '../../utils/Constants';
import { encrypt } from '../../utils/crypto';

const PROD_URL = Constants.PROD_URL;

//! Create backlog
export const createBacklog = (formData, history) => async (dispatch) => {
    if (isEmpty(formData.title) || isEmpty(formData.desc) || isEmpty(formData.priority)) {
        return await dispatch(setAlert('Make sure all fields are filled out', 'error'));
    }
    await dispatch({ type: types.CREATE_BACKLOG_PENDING });
    await dispatch(createBacklogReq({ ...formData }, history));
};

export const createBacklogReq = (formData, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.post(`${PROD_URL}/api/backlog/create_backlog`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.CREATE_BACKLOG_SUCCESS,
                payload: res.data.backlog
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            await history.push(`/backlogs/${encrypt(res.data.backlog._id)}`);
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.CREATE_BACKLOG_FAIL
            });
        });
};

//! Fetch backlogs
export const fetchBacklogs = () => async (dispatch) => {
    await dispatch({ type: types.FETCH_BACKLOGS_PENDING });
    await dispatch(fetchBacklogsReq());
};

export const fetchBacklogsReq = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.get(`${PROD_URL}/api/backlog/fetch_backlogs`, config)
        .then(async (res) => {
            await dispatch({
                type: types.FETCH_BACKLOGS_SUCCESS,
                payload: res.data.backlogs
            });
        }).catch(async (err) => {
            await dispatch({
                type: types.FETCH_BACKLOGS_FAIL
            });
        });
};

//! Update backlog
export const updateBacklog = (formData, backlogId) => async (dispatch) => {
    await dispatch({ type: types.UPDATE_BACKLOG_PENDING });
    await dispatch(updateBacklogReq({ ...formData }, backlogId));
};

export const updateBacklogReq = (formData, backlogId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.put(`${PROD_URL}/api/backlog/update_backlog/${backlogId}`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.UPDATE_BACKLOG_SUCCESS,
                payload: res.data.backlog
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.UPDATE_BACKLOG_FAIL
            });
        });
};

//! Fetch single backlog
export const fetchBacklog = (backlogId) => async (dispatch) => {
    await dispatch({ type: types.FETCH_BACKLOG_PENDING });
    await dispatch(fetchBacklogReq(backlogId));
};

export const fetchBacklogReq = (backlogId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.get(`${PROD_URL}/api/backlog/fetch_backlog/${backlogId}`, config)
        .then(async (res) => {
            await dispatch({
                type: types.FETCH_BACKLOG_SUCCESS,
                payload: res.data.backlog
            });
        }).catch(async (err) => {
            await dispatch({
                type: types.FETCH_BACKLOG_FAIL
            });
        });
};