import * as types from './types/auth';
import axios from 'axios';
import { setAlert } from './alert';
import { closeDialog } from './dialog';
import setAuthToken from '../../utils/setAuthToken';
import Constants from '../../utils/Constants';
import { isEmpty } from 'jvh-is-empty';
import { fetchPadlocks } from './padlock';
const PROD_URL = Constants.PROD_URL;

//! Register 
export const registerUser = (formData, history) => async (dispatch) => {
    await dispatch({ type: types.REGISTER_PENDING });
    await dispatch(registerUserReq({ ...formData }, history));
};

export const registerUserReq = (formData, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/auth/register`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.REGISTER_SUCCESS,
                payload: res.data.token
            });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            await history.push('/account');
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.REGISTER_FAIL
            });
        });
};

//! Setup user 
// export const setupUser = (formData, history) => async (dispatch) => {
//     await dispatch({ type: types.SETUP_PENDING });
//     await dispatch(setupUserReq({ ...formData }, history));
// };

// export const setupUserReq = (formData, history) => async (dispatch) => {
//     const config = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };

//     await axios.post(`${PROD_URL}/api/auth/setup`, formData, config)
//         .then(async (res) => {
//             await dispatch({
//                 type: types.SETUP_SUCCESS,
//                 payload: res.data.token
//             });
//             await dispatch(loadUser());
//             await dispatch(setAlert(res.data.serverMsg, 'success'));
//             // await history.push('/boards');
//         }).catch(async (err) => {
//             const error = err.response.data.serverMsg;
//             console.log(err);
//             if (error) {
//                 await dispatch(setAlert(error, 'error'));
//             }
//             await dispatch({
//                 type: types.SETUP_FAIL
//             });
//         });
// };

//! Login
export const login = (formData, history) => async (dispatch) => {
    await dispatch({ type: types.LOGIN_PENDING });
    await dispatch(loginReq({ ...formData }, history));
};

export const loginReq = (formData, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/auth/login`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.LOGIN_SUCCESS,
                payload: res.data.token
            });
            await dispatch(loadUser());
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            await history.push('/locker');
        })
        .catch((err) => {
            const error = err.response.data.serverMsg;
            console.log(err);
            if (error) {
                dispatch(setAlert(error, 'error'));
            }
            dispatch({
                type: types.LOGIN_FAIL
            });
        });
};

//! Load user
export const loadUser = () => async (dispatch) => {
    await dispatch({ type: types.LOAD_USER_PENDING });
    await dispatch(loadUserReq());
};

export const loadUserReq = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.get(`${PROD_URL}/api/auth/load_user`, config)
        .then(async (res) => {
            await dispatch({
                type: types.LOAD_USER_SUCCESS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: types.LOAD_USER_FAIL
            });
        });
};

//! Logout
export const logout = (history) => async (dispatch) => {
    await dispatch({ type: types.LOGOUT_PENDING });
    await dispatch(logoutReq(history));
};

export const logoutReq = (history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.get(`${PROD_URL}/api/auth/logout`, config)
        .then(async (res) => {
            await dispatch({ type: types.LOGOUT_SUCCESS });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            await history.push('/');
        })
        .catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.LOGOUT_FAIL
            });
            await history.push('/');
        });
};

//! Update user
export const updateUser = (formData) => async (dispatch) => {
    await dispatch({ type: types.UPDATE_USER_PENDING });
    await dispatch(updateUserReq({ ...formData }));
};

export const updateUserReq = (formData) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.put(`${PROD_URL}/api/auth/update_user`, formData, config)
        .then(async (res) => {
            await dispatch({
                type: types.UPDATE_USER_SUCCESS,
                payload: res.data.user
            });
            await dispatch(closeDialog());
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            console.log(err);
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.UPDATE_USER_FAIL
            });
        })
};

//! Change user password
export const changePassword = (formData, userEmail, userId, history) => async (dispatch) => {
    await dispatch({ type: types.CHANGE_PASSWORD_PENDING });
    await dispatch(changePasswordReq({ ...formData }, userEmail, userId, history));
};

export const changePasswordReq = (formData, userEmail, userId, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.put(`${PROD_URL}/api/auth/change_password/${userEmail}/${userId}`, formData, config)
        .then(async (res) => {
            await dispatch({ type: types.CHANGE_PASSWORD_SUCCESS });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            history.push('/account');
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.CHANGE_PASSWORD_FAIL
            });
        })
};

//! Forgot password
export const submitForgotPass = (email, history) => async (dispatch) => {
    await dispatch({ type: types.FORGOT_PASSWORD_PENDING });
    await dispatch(submitForgotPassReq(email, history));
};

export const submitForgotPassReq = (email, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/auth/forgot_password`, { email }, config)
        .then(async (res) => {
            await dispatch({ type: types.FORGOT_PASSWORD_SUCCESS });
            await history.push('/');
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch((err) => {
            const error = err.response.data.serverMsg;
            console.log(err);
            if (error) {
                dispatch(setAlert(error, 'error'));
            }
            dispatch({
                type: types.FORGOT_PASSWORD_FAIL
            });
        });
};

//! Check user security 
export const checkSecurity = (formData, lockerId) => async (dispatch) => {
    await dispatch({ type: types.CHECK_SECURITY_PENDING });
    await dispatch(checkSecurityReq({ ...formData }, lockerId));
};

export const checkSecurityReq = (formData, lockerId) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.put(`${PROD_URL}/api/auth/check_security`, formData, config)
        .then(async (res) => {
            await dispatch({ type: types.CHECK_SECURITY_SUCCESS });
            await dispatch(setAlert(res.data.serverMsg, 'success'));
            await dispatch(closeDialog());
            localStorage.setItem("securityQuestion", formData.question);
            localStorage.setItem("userEmail", formData.email);
            await dispatch(fetchPadlocks(lockerId));
        }).catch((err) => {
            const error = err.response.data.serverMsg;
            console.log(err);
            if (error) {
                dispatch(setAlert(error, 'error'));
            }
            dispatch({
                type: types.CHECK_SECURITY_FAIL
            });
        });
};

//! Re-enter password for user verification
export const reEnterPassword = (password, history, setHasVerified) => async (dispatch) => {
    await dispatch({ type: types.ENTER_PASSWORD_PENDING });
    await dispatch(reEnterPasswordReq(password, history, setHasVerified));
};

export const reEnterPasswordReq = (password, history, setHasVerified) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.post(`${PROD_URL}/api/auth/re_enter_password`, { password }, config)
        .then(async (res) => {
            await dispatch(closeDialog());
            await dispatch({
                type: types.ENTER_PASSWORD_SUCCESS
            });
            setHasVerified(true);
        }).catch(async (err) => {
            history.goBack();
            const error = err.response.data.serverMsg;
            console.log(err);
            if (error) {
                dispatch(setAlert(error, 'error'));
            }
            dispatch({
                type: types.ENTER_PASSWORD_FAIL
            });
        });
};

//! Terminate user
export const terminateUser = (formData, history) => async (dispatch) => {
    if (isEmpty(formData.password)) {
        return await dispatch(setAlert('Please enter your password', 'error'));
    }
    await dispatch({ type: types.TERMINATE_PENDING });
    await dispatch(terminateUserReq({ ...formData }, history));
};

export const terminateUserReq = (formData, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    await axios.put(`${PROD_URL}/api/auth/terminate_user`, formData, config)
        .then(async (res) => {
            await dispatch({ type: types.TERMINATE_SUCCESS });
            await dispatch(closeDialog());
            await dispatch(logout(history));
            await dispatch(setAlert(res.data.serverMsg, 'success'));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                dispatch(setAlert(error, 'error'));
            }
            dispatch({
                type: types.TERMINATE_FAIL
            });
        });
};