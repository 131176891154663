import React, { useState, useEffect } from 'react';

// utils
import Constants from '../../../utils/Constants';

const Switch = (props) => {
    const [onClick, setOnClick] = useState(false);
    const [onHover, setOnHover] = useState(false);
    const { onSwitchChange, predefinedValue, setDefault } = props;

    // watches for predefined values to set switch 
    useEffect(() => {
        if (predefinedValue !== undefined) {
            setOnClick(true);
            setOnHover(true);
        }
    }, []);

    // on click effect 
    useEffect(() => {
        if (onClick) {
            onSwitchChange(true);
        } else if (!onClick) {
            onSwitchChange(false);
        }
    }, [onClick]);

    // this sets the default values for the switch
    useEffect(() => {
        if (setDefault) {
            setOnClick(false);
            setOnHover(false);
        }
    }, [setDefault]);

    const mouseEnter = () => {
        setOnHover(true);
    };

    const mouseLeave = () => {
        setOnHover(false);
    };

    const styles = {
        switch: {
            backgroundColor: Constants.secondary,
            position: 'relative',
            width: '5rem',
            height: '2.5rem',
            borderRadius: '25px',
            cursor: 'pointer',
            WebkitTransition: 'all .3s ease',
            MozTransition: 'all .3s ease',
            OTransition: 'all .3s ease',
            transition: 'all .3s ease',
        },
        input: {
            position: 'absolute',
            left: '0px',
            top: '0px',
            width: '100px',
            height: '100px',
            zIndex: 1,
            opacity: 0,
            cursor: 'pointer',
            WebkitTransition: 'all .3s ease',
            MozTransition: 'all .3s ease',
            OTransition: 'all .3s ease',
            transition: 'all .3s ease',
        },
        circle: {
            position: 'absolute',
            left: onClick ? '2.5rem' : '0px',
            top: '0px',
            width: '1.9rem',
            height: '1.9rem',
            margin: '5px',
            zIndex: '2',
            background: 'black',
            borderRadius: '20px',
            opacity: onClick ? 1 : .4,
            boxShadow: !onClick ? '' : '0 10px 20px 0px rgba(0,0,0,.35)',
            WebkitTransition: 'all .3s ease',
            MozTransition: 'all .3s ease',
            OTransition: 'all .3s ease',
            transition: 'all .3s ease',
        }
    };

    return (
        <div
            style={styles.switch}
            className="switch"
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={() => setOnClick(!onClick)}
        >
            <div style={styles.circle} className="circle"></div>
        </div>
    )
};

export default Switch;