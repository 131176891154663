import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLockers
} from '../../../store/actions/locker';

// utils
import { decrypt } from '../../../utils/crypto';
import formatDropdownOptions from '../../../utils/formatDropdownOptions';

// components
import Input from '../../Layout/Input/Input';
import PasswordInput from '../../Layout/PasswordInput/PasswordInput';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Button from '../../Layout/Button/Button';
import Divider from '../../Layout/Divider/Divider';
import Switch from '../../Layout/Switch/Switch';
import CustomDropdown from '../../Layout/CustomDropdown/CustomDropdown';
import MiniLoader from '../../Layout/MiniLoader/MiniLoader';
import BulletCreator from '../../Padlocks/BulletCreator/BulletCreator';

const PadlockEdit = (props) => {
    const {
        padlock: {
            _id,
            title,
            notes,
            locker,
            lockerId,
            createdAt,
            updatedAt,
            hasTwoAuth,
            username,
            password,
            secondUsername,
            phone,
            encryptedBullets
        },
        dataChangeHandler,
        renderMobileView
    } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: !isEmpty(title) ? title : '',
        notes: !isEmpty(notes) ? notes : '',
        locker: !isEmpty(locker) ? locker : '',
        lockerId: !isEmpty(lockerId) ? lockerId : '',
        hasTwoAuth: hasTwoAuth !== null ? hasTwoAuth : false,
        username: !isEmpty(username) ? decrypt(username) : '',
        password: !isEmpty(password) ? decrypt(password) : '',
        secondUsername: !isEmpty(secondUsername) ? decrypt(secondUsername) : '',
        phone: !isEmpty(phone) ? decrypt(phone) : '',
        encryptedBullets: !isEmpty(encryptedBullets) ? encryptedBullets.map((bullet) => ({
            _id: bullet._id,
            title: bullet.title,
            text: decrypt(bullet.text)
        })) : []
    });
    const lockerState = useSelector((state) => state.locker);

    // fetch the lockers if they are empty
    useEffect(() => {
        if (!lockerState.fetching && isEmpty(lockerState.lockers)) {
            dispatch(fetchLockers());
        }
    }, [lockerState.fetching, lockerState.lockers]);

    // watches for changes happening to the form. 
    useEffect(() => {
        dataChangeHandler(formData);
    }, [formData]);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onLockerSelect = (data) => {
        setFormData({
            ...formData,
            locker: data.name,
            lockerId: data.value
        });
    };

    const switchChangeHandler = (isTrue) => {
        setFormData({
            ...formData,
            hasTwoAuth: isTrue
        });
    };

    // const onSubmit = async (e) => {
    //     e.preventDefault();

    // };

    const onBulletChange = (data) => {
        setFormData({
            ...formData,
            encryptedBullets: data
        });
    };

    const styles = {
        leftCol: {
            width: '100%',
            maxWidth: renderMobileView ? '30rem' : '45%'
        },
        rightCol: {
            width: '100%',
            maxWidth: renderMobileView ? '30rem' : '45%'
        }
    };
    return (
        <form>
            <Wrapper styles={{ justifyContent: renderMobileView ? 'center' : 'space-between', alignItems: 'flex-start', marginBottom: '2rem' }}>
                <div style={styles.leftCol}>
                    <div className="form-block">
                        <label className="light-text input-label">Title</label>
                        <Input
                            inputType="input"
                            onChange={(e) => onChange(e)}
                            value={formData.title}
                            placeholder="Padlock Title"
                            name="title"
                            type="text"
                        />
                    </div>
                    <div className="form-block">
                        <label className="light-text input-label">Locker</label>
                        {lockerState.fetching && <MiniLoader />}
                        {!lockerState.fetching && !isEmpty(lockerState.lockers) && <CustomDropdown
                            handleChange={onLockerSelect}
                            isPredefined={true}
                            predefinedValue={formData.locker}
                            type="lockers"
                            isSearchable={true}
                            options={formatDropdownOptions(lockerState.lockers, 'lockers')}
                            placeholder="Select a locker"
                        />}
                    </div>
                    <div className="form-block">
                        <label className="light-text input-label">Username</label>
                        <Input
                            inputType="input"
                            onChange={(e) => onChange(e)}
                            value={formData.username}
                            placeholder="Username"
                            name="username"
                            type="text"
                        />
                    </div>
                    <div className="form-block">
                        <label className="light-text input-label">Username</label>
                        <PasswordInput
                            onChange={(e) => onChange(e)}
                            value={formData.password}
                            placeholder="Password"
                            name="password"
                            viewable
                        />
                    </div>
                    <div className="form-block">
                        <label className="light-text input-label">Notes</label>
                        <Input
                            inputType="textarea"
                            onChange={(e) => onChange(e)}
                            value={formData.notes}
                            placeholder="Write a note..."
                            name="notes"
                            rows="6"
                        />
                    </div>
                </div>
                {!renderMobileView && <Divider vertical style={{ minHeight: '25rem' }} />}
                <div style={styles.rightCol}>
                    <div className="form-block">
                        <label className="light-text input-label">2 Auth</label>
                        <Switch
                            onSwitchChange={switchChangeHandler}
                            predefinedValue={formData.hasTwoAuth}
                        />
                    </div>
                    {formData.hasTwoAuth &&
                        (
                            <>
                                <div className="form-block">
                                    <label className="light-text input-label">Second Username</label>
                                    <Input
                                        inputType="input"
                                        onChange={(e) => onChange(e)}
                                        value={formData.secondUsername}
                                        placeholder="Username"
                                        name="secondUsername"
                                        type="text"
                                    />
                                </div>
                                <div className="form-block">
                                    <label className="light-text input-label">Phone</label>
                                    <Input
                                        inputType="input"
                                        onChange={(e) => onChange(e)}
                                        value={formData.phone}
                                        placeholder="(012) 345-6789"
                                        name="phone"
                                        type="tel"
                                    />
                                </div>
                            </>
                        )
                    }
                    <div className="form-block">
                        <label className="light-text input-label">Encrypted Bullets</label>
                        <BulletCreator
                            onBulletChange={onBulletChange}
                            predefinedBullets={formData.encryptedBullets}
                        />
                    </div>
                </div>
            </Wrapper>
        </form>
    );
};



export default PadlockEdit;