import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'jvh-is-empty';
import {
    fetchLockers,
    deleteLocker
} from '../../../store/actions/locker';
import { withRouter } from 'react-router-dom';
import {
    movePadlocks    
} from '../../../store/actions/padlock';

// utils
import Constants from '../../../utils/Constants';
import { decrypt } from '../../../utils/crypto';

// components
import Button from '../../Layout/Button/Button';
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Divider from '../../Layout/Divider/Divider';
import CustomDropdown from '../../Layout/CustomDropdown/CustomDropdown';
import MiniLoader from '../../Layout/MiniLoader/MiniLoader';
import Iconify from '../../Layout/Iconify/Iconify';

const MoreMenu = (props) => {
    const { closeDialog, lockerId, history } = props;
    const dispatch = useDispatch();
    const [radioBtns, setRadioBtns] = useState([
        'Move All Padlocks',
        'Delete Locker'
    ]);
    const [selectedRadio, setSelectedRadio] = useState(0);
    const { lockers, fetching, deleting } = useSelector((state) => state.locker);
    const padlockState = useSelector((state) => state.padlock);
    const [formData, setFormData] = useState({
        locker: '',
        lockerId: ''
    });

    // this is used to check and validate that the lockers are available 
    useEffect(() => {
        if (!fetching && isEmpty(lockers)) {
            dispatch(fetchLockers());
        }
    }, []);

    const onCloseClick = () => {
        dispatch(closeDialog());
    };

    const dropdownSelectHandler = (data) => {
        setFormData({
            locker: data.name,
            lockerId: data.value
        });
    };

    const renderDropdown = () => {
        if (fetching) {
            return <MiniLoader />
        } else if (!fetching && !isEmpty(lockers)) {
            return <CustomDropdown
                handleChange={dropdownSelectHandler}
                type="lockers"
                isSearchable={true}
                options={lockers.map((a) => {
                    if (a._id === decrypt(lockerId)) {
                        return null;
                    } else if (a._id !== decrypt(lockerId)) {
                        return { name: a.lockerName, value: a._id }
                    }
                }).filter((a) => a !== null)}
                placeholder="Select a locker"
            />
        }
    };

    // submit function
    const onSubmitHandler = async () => {
        // enter switch case to dictate what action we need to take
        switch (selectedRadio) {
            case 0: // move all padlocks
                if (isEmpty(formData.locker) || isEmpty(formData.lockerId)) {
                    await dispatch(closeDialog());
                    return;
                }   
                let padlocks = padlockState.padlocks;
                if (!isEmpty(padlocks)) {
                    let newFormData = {
                        padlockIdArr: padlocks.map(({ _id }) => _id),
                        ...formData
                    };
                    await dispatch(movePadlocks(newFormData));
                }
                break;
            case 1: // locker deletion 
                await dispatch(deleteLocker(formData, decrypt(lockerId), history));
                break;
            default: return;
        }
    };

    const styles = {
        radioBtn: {
            cursor: 'pointer',
            margin: '.5rem 1rem',
            padding: '.6rem 1rem',
            WebkitTransition: 'all .4s ease',
            MozTransition: 'all .4s ease',
            OTransition: 'all .4s ease',
            transition: 'all .4s ease',
            border: `1px solid ${Constants.secondary}`
        },
        radioTxt: {
            padding: 0,
            margin: 0,
            WebkitTransition: 'all .4s ease',
            MozTransition: 'all .4s ease',
            OTransition: 'all .4s ease',
            transition: 'all .4s ease',
            color: 'black'
        },
        // text that displays when selecting to delete a locker appears
        warnText: {
            padding: 0,
            margin: '1rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '.9rem'
        },
        // warning icon
        warnIcon: {
            color: 'yellow',
            fontSize: '2rem',
            margin: '0 .5rem'
        }
    };

    const selectBtnHandler = (i) => {
        setSelectedRadio(i);
    };

    const renderRadioBtns = () => {
        return Object.values(radioBtns).map((btn, i) => {
            return (
                <main
                    className="large-shadow large-rad"
                    onClick={() => selectBtnHandler(i)} key={i}
                    style={{
                        ...styles.radioBtn,
                        backgroundColor: selectedRadio === i ? Constants.secondary : 'transparent'
                    }}
                >
                    <h4 style={{
                        ...styles.radioTxt,
                        color: selectedRadio === i ? 'black' : 'white'
                    }}>{btn}</h4>
                </main>
            );
        });
    };

    return (
        <>
            <Wrapper>
                {renderRadioBtns()}
            </Wrapper>
            <Divider style={{ margin: '1rem 0', borderColor: Constants.lightGrey }} />
            <div className="form-block">
                <label className="input-label light-text">Select a locker location</label>
                {renderDropdown()}
            </div>
            <Wrapper>
                {selectedRadio === 1 &&
                    <p className="light-text" style={styles.warnText}>
                        <Iconify iconName="warning" style={styles.warnIcon} />
                        WARNING: Not selecting a locker will delete all padlocks inside!
                        <Iconify iconName="warning" style={styles.warnIcon} />
                    </p>
                }
            </Wrapper>
            <Wrapper styles={{ justifyContent: 'space-evenly' }}>
                <Button type="button" onClick={onCloseClick}>Close</Button>
                <Button onClick={onSubmitHandler} loading={deleting || padlockState.updating}  type="button">Save</Button>
            </Wrapper>
        </>
    );
};

export default withRouter(MoreMenu);