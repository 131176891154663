import React from 'react';
import { useDispatch } from 'react-redux';

// utils
import Constants from '../../../utils/Constants';
import { decrypt } from '../../../utils/crypto';
import { copyValue } from '../../../utils/copyValue';

// components
import Iconify from '../../Layout/Iconify/Iconify';
import Tooltip from '../../Layout/Tooltip/Tooltip';

const Bullets = (props) => {
    const { bullet: { _id, title, text } } = props;
    const dispatch = useDispatch();

    return (
        <div className="large-rad large-shadow" style={styles.card}>
            <div style={styles.cardHead}>
                <Iconify iconName="rightArrow" style={styles.arrow} />
                <p style={styles.titleText} className="light-text">{title}</p>
            </div>
            <div style={styles.cardFooter}>
                <p className="light-text" style={styles.text}>{decrypt(text)}</p>
                <span onClick={() => copyValue(decrypt(text), dispatch)}>
                    <Tooltip text="Copy Bullet">
                        <Iconify iconName="copy" style={styles.copyIcon} />
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

const styles = {
    card: {
        backgroundColor: Constants.bodyBg,
        width: '100%',
        margin: '1rem 0',
        padding: '.5rem'
    },
    // card head
    cardHead: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        padding: 0
    },
    titleText: {
        margin: '0 0 0 .3rem',
        padding: 0,
        fontWeight: 600,
        fontSize: '1.2rem'
    },
    // arrow icon
    arrow: {
        color: Constants.secondary,
        margin: 0,
        padding: 0,
        fontSize: '1.2rem'
    },
    text: {
        padding: 0,
        fontSize: '1rem', 
        fontWeight: 400,
        opacity: .8,
        margin: 0
    },
    cardFooter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        margin: 0,
        padding: 0,
        justifyContent: 'space-between',
        margin: '.6rem 0 0 0'
    },
    copyIcon: {
        color: Constants.secondary,
        fontSize: '1.2rem',
        cursor: 'pointer',
    }
};

export default Bullets;