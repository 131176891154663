//! SETUP TYPES
export const SETUP_PENDING = 'SETUP_PENDING';
export const SETUP_FAIL = 'SETUP_FAIL';
export const SETUP_SUCCESS = 'SETUP_SUCCESS';

//! REGISTER TYPES
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

//! LOGIN TYPES
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//! LOAD USER TYPES
export const LOAD_USER_PENDING = 'LOAD_USER_PENDING';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

//! LOGOUT TYPES
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

//! UPDATE USER TYPES
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

//! CHANGE PASSWORD TYPES
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';                  

//! FORGOT PASSWORD TYPES
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

//! CHECK SECURITY TYPES
export const CHECK_SECURITY_PENDING = 'CHECK_SECURITY_PENDING';
export const CHECK_SECURITY_FAIL = 'CHECK_SECURITY_FAIL';
export const CHECK_SECURITY_SUCCESS = 'CHECK_SECURITY_SUCCESS';

//! RE-ENTER PASSWORD TYPES
export const ENTER_PASSWORD_PENDING = 'ENTER_PASSWORD_PENDING';
export const ENTER_PASSWORD_FAIL = 'ENTER_PASSWORD_FAIL';
export const ENTER_PASSWORD_SUCCESS = 'ENTER_PASSWORD_SUCCESS';

//! TERMINATE USER TYPES
export const TERMINATE_PENDING = 'TERMINATE_PENDING';
export const TERMINATE_FAIL = 'TERMINATE_FAIL';
export const TERMINATE_SUCCESS = 'TERMINATE_SUCCESS';