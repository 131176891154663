import React, { useState, useEffect } from 'react';
import { isEmpty } from 'jvh-is-empty';
import { useDispatch, useSelector } from 'react-redux';
import {
    openConfirmModal
} from '../../../store/actions/confirmModal';
import {
    openDialog
} from '../../../store/actions/dialog';
import {
    updatePadlock,
    deletePadlock
} from '../../../store/actions/padlock';
import { withRouter } from 'react-router-dom';

// utils
import { decrypt } from '../../../utils/crypto';
import Constants from '../../../utils/Constants';
import { copyValue } from '../../../utils/copyValue';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Tooltip from '../../Layout/Tooltip/Tooltip';
import Iconify from '../../Layout/Iconify/Iconify';
import Divider from '../../Layout/Divider/Divider';
import Bullets from '../Bullets/Bullets';
import RoundButton from '../../Layout/RoundButton/RoundButton';
import PadlockEdit from '../PadlockEdit/PadlockEdit';
import LockerChangeForm from '../../Padlocks/LockerChangeForm/LockerChangeForm';

const PadlockData = (props) => {
    const { padlock } = props;
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(null);
    const { updating, deleting } = useSelector((state) => state.padlock);
    const [renderMobileView, setRenderMobileView] = useState(false);
    const {
        _id,
        title,
        notes,
        locker,
        lockerId,
        createdAt,
        updatedAt,
        hasTwoAuth,
        username,
        password,
        secondUsername,
        phone,
        encryptedBullets
    } = padlock;
    // this variable returns the format of the label we want to render to the user as well as if it's copy-able
    const PADLOCK_CONFIG = {
        locker: {
            label: 'Locker',
            isCopy: false
        },
        secondUsername: {
            label: '2nd Username',
            isCopy: true
        },
        phone: {
            label: 'Phone',
            isCopy: true
        },
        hasTwoAuth: {
            label: '2 Auth',
            isCopy: false
        },
        encryptedBullets: {
            label: 'Encrypted Bullets',
            isCopy: false
        },
        notes: {
            label: 'Notes',
            isCopy: false
        }
    };

    useEffect(() => {
        const handleRules = () => {
            if (window.innerWidth <= 990) {
                setRenderMobileView(true);
            } else {
                setRenderMobileView(false);
            }
        };
        window.addEventListener('resize', handleRules)
        return () => window.removeEventListener('resize', handleRules);
    }, []);

    const renderBullets = () => {
        return Object.values(encryptedBullets).map((bullet, i) => {
            return <Bullets bullet={bullet} key={i + 1} />
        });
    };

    const renderPhone = (phone) => {
        let cleaned = ('' + phone).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    };

    const renderEditForm = () => <PadlockEdit renderMobileView={renderMobileView} dataChangeHandler={dataChangeHandler} padlock={padlock} />

    const setEdit = () => {
        setIsEditing(!isEditing);
    };

    const dataChangeHandler = (formData) => {
        setFormData(formData);
    };

    const saveHandler = async (e) => {
        e.preventDefault();

        if (!canAcceptUpdate()) {
            setIsEditing(false);
            return;
        }
        await dispatch(updatePadlock(formData, _id, setIsEditing));
    };

    // checks to see if data is different from the original before making the request to update
    const canAcceptUpdate = () => {
        const {
            title,
            notes,
            locker,
            lockerId,
            hasTwoAuth,
            username,
            password,
            secondUsername,
            phone,
            encryptedBullets
        } = formData;

        // create function to compare encrypted bullets
        const bulletsAreSame = () => {
            // first validate the length is the same
            if (encryptedBullets.length === padlock.encryptedBullets.length) {
                for (let updatedObj of encryptedBullets) {
                    let foundOldBullet = padlock.encryptedBullets.find((a) => a._id === updatedObj._id);
                    // if not found old bullet we return false because that means theres a new one added 
                    if (isEmpty(foundOldBullet)) {
                        return false;
                    }
                    // else we we check to see if a title or text value has changed
                    else if (!isEmpty(foundOldBullet)) {
                        // iterate over obj keys to compare values
                        for (let prop in updatedObj) {
                            let oldPropVal;
                            if (prop === 'text') {
                                oldPropVal = decrypt(foundOldBullet[prop]);
                            } else {
                                oldPropVal = foundOldBullet[prop]
                            }
                            // if we find a key value that is not equal we return false
                            if (updatedObj[prop] !== oldPropVal) {
                                return false;
                            }
                        }
                    }
                }
            } else {
                return false;
            }
            return true;
        };

        // large if check... I know this should be changed... bad practice
        if (
            title === padlock.title &&
            notes === padlock.notes &&
            locker === padlock.locker &&
            lockerId === padlock.lockerId &&
            hasTwoAuth === padlock.hasTwoAuth &&
            username === decrypt(padlock.username) &&
            password === decrypt(padlock.password) &&
            secondUsername === decrypt(padlock.secondUsername) &&
            phone === decrypt(padlock.phone) &&
            bulletsAreSame()
        ) {
            return false;
        }
        return true;
    };

    const renderFolderMoveForm = (closeDialog) => <LockerChangeForm
        locker={locker}
        lockerId={lockerId}
        closeDialog={closeDialog}
        padlockId={_id}
    />

    const onConfirmDelete = async () => {
        await dispatch(deletePadlock(_id, () => { props.history.goBack() }));
    };

    const renderDataFooter = () => {
        return (
            <main className="large-shadow large-rad" style={styles.rootFooter}>
                <Wrapper styles={{ justifyContent: 'space-evenly' }}>
                    <Tooltip onClick={() => dispatch(openConfirmModal('Are you sure you want to delete this?', onConfirmDelete))} text="Delete">
                        <RoundButton loading={deleting}><Iconify iconName="delete" /></RoundButton>
                    </Tooltip>
                    <Tooltip onClick={() => dispatch(openDialog('Change Locker', renderFolderMoveForm, 30))} text="Move Location">
                        <RoundButton><Iconify iconName="move" /></RoundButton>
                    </Tooltip>
                    {!isEditing &&
                        <Tooltip
                            onClick={(e) => {
                                setIsEditing(true);
                            }}
                            text={`${!isEditing ? 'Edit' : 'Save'}`}
                        >
                            <RoundButton loading={updating}><Iconify iconName={`${!isEditing ? 'edit' : 'save'}`} /></RoundButton>
                        </Tooltip>
                    }
                    {isEditing &&
                        <Tooltip
                            onClick={(e) => {
                                saveHandler(e);
                            }}
                            text={`${!isEditing ? 'Edit' : 'Save'}`}
                        >
                            <RoundButton loading={updating}><Iconify iconName={`${!isEditing ? 'edit' : 'save'}`} /></RoundButton>
                        </Tooltip>
                    }
                </Wrapper>
            </main>
        );
    };

    // this function handles rendering balance between the left and right columns of the data
    const renderColLayout = () => {
        // need to always initialize the top left column with the title of padlock
        let leftCol = [
            {
                label: 'Title',
                value: padlock.title,
                isCopy: false
            },
            {
                label: 'Username',
                value: decrypt(padlock.username),
                isCopy: true
            },
            {
                label: 'Password',
                value: decrypt(padlock.password),
                isCopy: true
            }
        ];
        let rightCol = [];
        for (const [key, val] of Object.entries(padlock)) {
            const ToSkip = {
                title,
                _id,
                createdAt,
                updatedAt,
                lockerId,
                username,
                password
            };
            const ToDecode = {
                // username,
                secondUsername,
                encryptedBullets,
                // password,
                phone
            };

            // if the key is included in the to skip const then we skip over it because we don't want to render that in the columns
            if (key in ToSkip) {
                continue;
            }
            // check and see if the value is empty... 
            // if it is then we skip the process
            if (isEmpty(val)) {
                continue;
            }
            // create new object to push to columns sides.
            // if there is an encoded value then we decode it
            let verifiedVal;
            if (key in ToDecode) {
                if (key === 'encryptedBullets') {
                    verifiedVal = val
                } else {
                    verifiedVal = decrypt(val);
                }
            }
            let newObj = {
                label: PADLOCK_CONFIG[key].label,
                value: !verifiedVal ? val : verifiedVal,
                isCopy: PADLOCK_CONFIG[key].isCopy
            };
            // dictates which side to put the cur key value on
            if (leftCol.length <= rightCol.length) {
                if (newObj.label === 'Encrypted Bullets' || newObj.label === 'Notes') {
                    // add either encrypted bullets or notes to the end of the left array
                    leftCol.splice(leftCol.length, 0, newObj)
                    continue;
                } else {
                    leftCol.splice(3, 0, newObj);
                    continue;
                }
            } else if (leftCol.length > rightCol.length) {
                if (newObj.label === 'Encrypted Bullets' || newObj.label === 'Notes') {
                    // add either encrypted bullets or notes to the end of the right array
                    rightCol.splice(rightCol.length, 0, newObj)
                    continue;
                } else {
                    rightCol.unshift(newObj);
                    continue;
                }
            }
        }

        return {
            leftCol,
            rightCol
        };
    };

    //! returns the boolean yes or no value if a field in the padlock is a bool
    //! this is TBD
    const renderBooleanValue = (boolVal) => {

    };

    // renders the xml for the columns so we only have to use one xml col
    const renderCol = (col, i) => {
        return (
            <div key={i + 1}>
                <Wrapper styles={{ justifyContent: 'flex-start', marginTop: '.8rem' }}>
                    <h3 style={styles.label} className="light-text">{col.label}</h3>
                </Wrapper>
                {col.label !== 'Encrypted Bullets' ?
                    <Wrapper styles={{ justifyContent: 'space-between', height: 'auto', margin: 0, padding: 0 }}>
                        <span className="large-shadow large-rad" style={{
                            ...styles.dataItemFrame,
                            height: 'auto',
                            minHeight: col.label === 'Notes' ? '8rem' : '3rem',
                            alignItems: col.label === 'Notes' && 'flex-start'
                        }}>
                            {typeof col.value !== 'boolean' &&
                                <p className="light-text" style={styles.dataItem}>
                                    {col.label !== 'Phone' ? col.value : renderPhone(col.value)}
                                </p>
                            }
                            {typeof col.value !== 'boolean' && col.isCopy &&
                                <Tooltip onClick={() => copyValue(col.value, dispatch)} text={`Copy ${col.label}`}>
                                    <RoundButton style={styles.copyBtn}>
                                        <Iconify style={styles.copyIcon} iconName="copy" />
                                    </RoundButton>
                                </Tooltip>
                            }
                            {typeof col.value === 'boolean' && <p className="light-text" style={styles.dataItem}>{col.value ? 'Yes' : 'No'}</p>}
                        </span>
                    </Wrapper>
                    :
                    renderBullets()
                }
            </div>
        );
    };

    // renders either the data or the editing for upon submission
    const renderData = () => {
        if (!isEditing) {
            const { leftCol, rightCol } = renderColLayout();
            return (
                <Wrapper styles={styles.dataWrapper}>
                    <div style={styles.leftCol}>
                        {Object.values(leftCol).map((col, i) => {
                            return renderCol(col, i);
                        })}
                    </div>
                    {!renderMobileView && <Divider vertical style={styles.dataDivider} />}
                    <div style={styles.rightCol}>
                        {Object.values(rightCol).map((col, i) => {
                            return renderCol(col, i)
                        })}
                    </div>
                </Wrapper>
            );
        }
        else if (isEditing) {
            return renderEditForm();
        }
    };

    const styles = {
        root: {
            backgroundColor: Constants.main,
            width: '90%',
            maxWidth: '64rem',
            padding: '2rem 1rem 1rem 1rem',
            margin: '0 0 3rem 0'
        },
        // copy button for each copy line
        copyBtn: {
            backgroundColor: Constants.bodyBg,
            height: '2rem',
            width: '2rem'
        },
        // copy icon inside button
        copyIcon: {
            fontSize: '1.1rem',
            color: Constants.secondary,
            marginLeft: '.1rem'
        },
        // wrapper around both data columns
        dataWrapper: {
            justifyContent: renderMobileView ? 'center' : 'space-between',
            alignItems: 'flex-start',
            padding: '0 1rem 1rem 1rem',
            position: 'relative',
            overflow: 'hidden'
        },
        // divider in between both columns
        dataDivider: {
            height: '50rem',
            position: 'absolute',
            top: 0,
            left: '50%',
            WebkitTransform: 'translateX(-50%)',
            MozTransform: 'translateX(-50%)',
            OTransform: 'translateX(-50%)',
            transform: 'translateX(-50%)',
        },
        // left column of card
        leftCol: {
            width: '100%',
            maxWidth: renderMobileView ? '30rem' : '45%'
        },
        rightCol: {
            width: '100%',
            maxWidth: renderMobileView ? '30rem' : '45%'
        },
        // frame that contains the label and data for  className="small-shadow large-rad"each value
        dataItemFrame: {
            margin: '.8rem 0',
            backgroundColor: Constants.bodyBg,
            width: '100%',
            overflow: 'hidden',
            padding: '.5rem .4rem',
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'row wrap',
            justifyContent: 'space-between'
        },
        // label for each data item
        label: {
            fontSize: '1.2rem',
            padding: 0,
            margin: 0
        },
        // data for each data item
        dataItem: {
            padding: 0,
            margin: 0,
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            lineHeight: '1.2rem',
        },
        // container for the notes
        noteBox: {
            backgroundColor: Constants.bodyBg,
            width: '100%',
            height: '100%',
            minHeight: '8rem',
            marginTop: '.8rem',
            padding: '.8rem .5rem',
        },
        // text data for the notes
        noteText: {
            padding: 0,
            margin: 0
        },
        // this is the footer at the bottom of the card
        rootFooter: {
            width: '100%',
            backgroundColor: Constants.bodyBg,
            marginBottom: '-2rem',
            marginTop: '2rem',
            padding: '.8rem .5rem',
            position: 'relative'
        }
    };

    return (
        <section className="large-shadow large-rad" style={styles.root}>
            {renderData()}
            {renderDataFooter()}
        </section>
    );
};



export default withRouter(PadlockData);