import * as types from './types/code';
import { setAlert } from './alert';
import axios from 'axios';
import { openDialog, closeDialog } from './dialog';
import Constants from '../../utils/Constants';
// utils 
import { encrypt } from '../../utils/crypto';

const PROD_URL = Constants.PROD_URL;

//! Create code
export const createCode = (xmlFunc, settingType) => async (dispatch) => {
    await dispatch({ type: types.CREATE_CODE_PENDING });
    await dispatch(createCodeReq(xmlFunc, settingType));
};

export const createCodeReq = (xmlFunc, settingType) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };
    const setXml = (closeDialog, settingType) => xmlFunc(closeDialog, settingType);

    await axios.post(`${PROD_URL}/api/code/create_code`, {}, config)
        .then(async (res) => {
            await dispatch({
                type: types.CREATE_CODE_SUCCESS,
                payload: res.data.codeLen
            });
            await dispatch(openDialog('User Settings', () => setXml(closeDialog, settingType), 40));
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.CREATE_CODE_FAIL
            });
        });
};

//! Verify code
export const verifyCode = (code, settingType, history) => async (dispatch) => {
    await dispatch({ type: types.VERIFY_CODE_PENDING });
    await dispatch(verifyCodeReq(code, settingType, history));
};

export const verifyCodeReq = (code, settingType, history) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Client-Id': process.env.REACT_APP_COVERTLOCKER_CLIENT_ID,
            'Client-Secret': process.env.REACT_APP_COVERTLOCKER_CLIENT_SECRET
        }
    };

    await axios.post(`${PROD_URL}/api/code/verify`, { code }, config)
        .then(async (res) => {
            await dispatch({
                type: types.VERIFY_CODE_SUCCESS
            });
            // check if the setting type is password. if it is then we redirect
            if (settingType === 'password' && res.data.granted) {
                history.push(`/account/change_password/${encrypt(res.data.userId)}`);
                await dispatch(closeDialog());
            } 
        }).catch(async (err) => {
            const error = err.response.data.serverMsg;
            if (error) {
                await dispatch(setAlert(error, 'error'));
            }
            await dispatch({
                type: types.VERIFY_CODE_FAIL
            });
        });
};

