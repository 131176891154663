import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { marked } from 'marked';

// utils
import Constants from '../../../utils/Constants';
import { encrypt } from '../../../utils/crypto';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import Divider from '../../Layout/Divider/Divider';
import Tooltip from '../../Layout/Tooltip/Tooltip';

const BacklogItem = (props) => {
    const { backlog, history } = props;

    const onBacklogClick = () => {
        history.push(`/backlogs/${encrypt(backlog._id)}`);
    };

    const styles = {
        card: {
            width: '95%',
            maxWidth: '60rem',
            padding: '1rem 1.5rem',
            backgroundColor: Constants.main,
            margin: '2rem 0 0 0',
            cursor: 'pointer'
        },
        title: {
            padding: 0,
            margin: 0,
            fontSize: '1.8rem'
        },
        markdownFrame: {
            backgroundColor: 'white',
            padding: '0 .5rem',
            overflow: 'hidden',
            height: '20rem',
            position: 'relative'
        },
        updatedAtText: {
            textAlign: 'center',
            fontSize: '.8rem',
            fontWeight: 700,
            opacity: '.8',
            margin: '2rem 0 1rem 0'
        },
        priorityWrap: {
            height: '2rem',
            width: '2rem', 
            borderRadius: '50%',
            backgroundColor: Constants.secondary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        },
        priorityText: { 
            fontSize: '1.2rem',
            textAlign: 'center'
        }
    };

    return (
        <main onClick={onBacklogClick} className="large-rad large-shadow" style={styles.card}>
            <Wrapper styles={{ justifyContent: 'space-between' }}>
                <h3 className="light-text" style={styles.title}>{backlog.title}</h3>
                <Tooltip text="Priority Level">
                    <div style={styles.priorityWrap}>
                        <h6 style={styles.priorityText}>{backlog.priority}</h6>
                    </div>
                </Tooltip>
            </Wrapper>
            <Divider style={{ margin: '1rem 0' }} />
            <div style={styles.markdownFrame}>
                <div className="small-rad" style={styles.markdownFrame} id="markdown_container" dangerouslySetInnerHTML={{ __html: marked(backlog.desc, { sanitize: true }) }} />
            </div>
            <p style={styles.updatedAtText} className="light-text">Last Updated on <Moment format="lll">{backlog.updatedAt}</Moment></p>
        </main>
    );
};


export default withRouter(BacklogItem);