import React from 'react';

// utils
import Constants from '../../../utils/Constants';

// components
import Iconify from '../Iconify/Iconify';

const Footer = (props) => {
    
    const returnYear = () => {
        return new Date().getFullYear();
    };

    const handleOwnerRedirect = () => {
        window.open('https://codevh.com/', '_blank');
    };

    return (
        <footer style={styles.footer}>
            <p className="light-text" style={styles.textWrap}>
                <span style={styles.company}>CovertLocker</span>
                <span style={styles.cc}>&copy;</span>
                <span style={styles.year}>{returnYear()}</span>
            </p>
            <p className="light-text" style={styles.madeByWrap}>
                Built with <Iconify iconName="coffee" style={styles.coffee} /> by 
                <span onClick={handleOwnerRedirect} style={styles.name}>Jeffrey VanHorn</span>
            </p>
        </footer>
    );
};

const styles = {
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        minHeight: '2rem',
        backgroundColor: Constants.main,
    },
    textWrap: {
        padding: 0,
        margin: '.5rem 0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '.8rem',
        fontWeight: 700
    },
    cc: {
        color: Constants.secondary,
        margin: '0 .3rem'
    },
    year: {

    },
    madeByWrap: {
        padding: 0,
        margin: '.3rem 0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '.7rem'
    },
    coffee: {
        margin: '0 .3rem',
        color: Constants.secondary,
        fontSize: '.9rem'
    },
    name: {
        color: Constants.secondary,
        fontWeight: 700,
        cursor: 'pointer'
    }
};

export default Footer;